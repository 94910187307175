import * as React from 'react';
import {
    learningPathDescriptionLabel,
    learningPathLabel,
    statusNoteText,
} from '../LearningPath.constants';
import {
    thumbnailImageStyles,
    learningPathHeaderCardProps,
    headerCardContentProps,
    detailStackStyles,
    lpLabelStyle,
    descriptionStyles,
    completionStatusStackStyles,
    detailStackStylesOnHeaderCardDisplay,
    statusNoteStyles,
} from '../LearningPath.styles';
import { Stack, Image, Icon } from '@fluentui/react';
import {
    CardType,
    childrenGap,
    fontClassNames,
    Spacing,
} from '../../Shared/constants';
import { LabeledValue } from '../../Shared/Components/LabeledValue/LabeledValue';
import { formatDateString } from '../../Shared/date';
import { StatusBar } from '../../Shared/Components/StatusBar/StatusBar';
import { CompletionStatus } from '../../Shared/Components/CompletionStatus/CompletionStatus';
import { CollapsibleDescription } from '../../Shared/Components/CollapsibleDescription/CollapsibleDescription';
import { useLearningPathState } from '../useLearningPathState';
import {
    getChildrenElementType,
    getCompletedItemsCount,
    getCompletionMessage,
} from '../LearningPath.utils';
import { styleForLearningPathElementIcon } from './LearningPathHeaderCard.styles';
import { CustomProgressIndicator } from '../../Shared/Components/CustomProgressIndicator/CustomProgressIndicator';

export function LearningPathHeaderCard(): React.ReactElement {
    const { learningPath, targeting } = useLearningPathState();
    const completedCount = getCompletedItemsCount(learningPath.parentGroup);
    return (
        <Stack {...learningPathHeaderCardProps}>
            <Stack tokens={childrenGap[16]} {...headerCardContentProps}>
                <Stack horizontal style={{ justifyContent: 'space-between' }}>
                    <Stack
                        tokens={childrenGap[24]}
                        styles={{
                            root: {
                                marginBottom: Spacing.SIZE_16,
                            },
                        }}
                        horizontal
                        verticalAlign="start"
                        wrap
                    >
                        <Image
                            src={learningPath.imagePath}
                            alt=""
                            width={160}
                            height={90}
                            styles={thumbnailImageStyles}
                        />
                        <Stack tokens={childrenGap[8]}>
                            <h1
                                className={
                                    fontClassNames.headerCardHeaderStyles
                                }
                            >
                                {learningPath.name}
                            </h1>
                            <StatusBar
                                status={learningPath.status}
                                expiryDate={learningPath.parentGroup.expiryDate}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={childrenGap[24]}>
                    <Stack tokens={childrenGap[8]}>
                        <Stack.Item styles={lpLabelStyle}>
                            {learningPathDescriptionLabel}
                        </Stack.Item>
                        <Stack.Item styles={statusNoteStyles}>
                            {statusNoteText}
                        </Stack.Item>
                        <CollapsibleDescription
                            title={learningPath.name}
                            description={learningPath.description}
                            defaultStyle={descriptionStyles}
                            cardType={CardType.Header}
                        />
                        {targeting.isActive && (
                            <Stack
                                tokens={childrenGap[24]}
                                styles={detailStackStylesOnHeaderCardDisplay}
                                wrap
                            >
                                {targeting?.completeByDate && (
                                    <Stack
                                        horizontal
                                        verticalAlign="center"
                                        wrap
                                    >
                                        <Icon
                                            iconName="Calendar"
                                            styles={
                                                styleForLearningPathElementIcon
                                            }
                                        />
                                        <LabeledValue
                                            label={learningPathLabel.completeBy}
                                            value={formatDateString(
                                                targeting.completeByDate
                                            )}
                                        />
                                    </Stack>
                                )}

                                <Stack horizontal verticalAlign="center" wrap>
                                    <Icon
                                        iconName="Group"
                                        styles={styleForLearningPathElementIcon}
                                    />
                                    <LabeledValue
                                        label={learningPathLabel.assignedBy}
                                        value={targeting.assignedBy}
                                    />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                verticalAlign={'center'}
                horizontal
                styles={completionStatusStackStyles}
            >
                <Stack
                    horizontal
                    styles={{ root: { margin: '24px' } }}
                    tokens={childrenGap[24]}
                    verticalAlign="center"
                >
                    <CustomProgressIndicator
                        requiredCount={
                            learningPath.parentGroup?.groupData
                                ?.requiredItemCount ?? 0
                        }
                        completedCount={completedCount}
                    />
                    <CompletionStatus
                        totalCompleted={completedCount}
                        requiredCount={
                            learningPath.parentGroup
                                ? learningPath.parentGroup.groupData
                                      .requiredItemCount
                                : 0
                        }
                        message={getCompletionMessage(learningPath.parentGroup)}
                        childrenType={getChildrenElementType(
                            learningPath.parentGroup
                        )}
                    />
                </Stack>

                {targeting.isActive && (
                    <Stack
                        horizontal
                        tokens={childrenGap[24]}
                        styles={detailStackStyles}
                        wrap
                    >
                        {targeting?.completeByDate && (
                            <Stack horizontal verticalAlign="center" wrap>
                                <Icon
                                    iconName="Calendar"
                                    styles={styleForLearningPathElementIcon}
                                />
                                <LabeledValue
                                    label={learningPathLabel.completeBy}
                                    value={formatDateString(
                                        targeting.completeByDate
                                    )}
                                />
                            </Stack>
                        )}
                        <Stack horizontal verticalAlign="center" wrap>
                            <Icon
                                iconName="Group"
                                styles={styleForLearningPathElementIcon}
                            />
                            <LabeledValue
                                label={learningPathLabel.assignedBy}
                                value={targeting.assignedBy}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}
