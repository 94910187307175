import * as React from 'react';
import { CoherenceHeader } from '@coherence-design-system/controls/lib/header/CoherenceHeader';
import {
    SearchSettings,
    ICoherenceHeaderProps,
    FarRightNotificationPanelProps,
    FarRightSettingsPanelProps,
    FarRightFeedbackPanelProps,
    FarRightProfilePanelProps,
} from '@coherence-design-system/controls/lib/header/CoherenceHeader.types';
import { useUser } from '@employee-experience/common/lib/useUser';
import { useGraphPhoto } from '@employee-experience/common/lib/useGraphPhoto';
import {
    UserEvent,
    EventType,
    UsageEventName,
} from '@employee-experience/common/lib/Models/';
import { HeaderPanel } from './Header.types';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Stack } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';

export function Header(props: ICoherenceHeaderProps): React.ReactElement {
    const { farRightSettings, searchSettings, ...otherProps } = props;
    const { telemetryClient, authClient } = React.useContext(ComponentContext);
    const user = useUser();
    const photo = useGraphPhoto();

    const searchConfig: SearchSettings | undefined = searchSettings;
    if (searchConfig) {
        const originalOnSearch = searchConfig.onSearch;
        searchConfig.onSearch = (searchTerm: string): void => {
            if (!originalOnSearch) return;

            const searchEvent: UserEvent = {
                subFeature: 'Header.Search',
                businessTransactionId: searchTerm,
                type: EventType.User,
                eventName: UsageEventName.TextChanged,
            };
            telemetryClient.trackEvent(searchEvent);

            originalOnSearch(searchTerm);
        };
    }

    const handleLogOutClicked = (): void => {
        const logout: UserEvent = {
            subFeature: 'Header.Logout',
            type: EventType.User,
            eventName: UsageEventName.ButtonClicked,
        };
        telemetryClient.trackEvent(logout);

        authClient.logOut().catch();
    };

    const getPanelOpenHandler = (panel: HeaderPanel): (() => void) => {
        return (): void => {
            const panelEvent: UserEvent = {
                subFeature: `Header.${panel.toString()}`,
                type: EventType.User,
                eventName: UsageEventName.PanelOpened,
                businessTransactionId: panel.toString(),
            };
            telemetryClient.trackEvent(panelEvent);
            telemetryClient.startTrackPage(panel);
        };
    };

    const getPanelDismissHandler = (panel: HeaderPanel): (() => void) => {
        return (): void => {
            const panelEvent: UserEvent = {
                subFeature: `Header.${panel.toString()}`,
                type: EventType.User,
                eventName: UsageEventName.PanelClosed,
                businessTransactionId: panel.toString(),
            };
            telemetryClient.trackEvent(panelEvent);
            telemetryClient.stopTrackPage(panel);
        };
    };

    const notificationConfig: FarRightNotificationPanelProps | undefined =
        farRightSettings?.notificationsSettings;
    if (notificationConfig) {
        notificationConfig.panelSettings.onOpened = getPanelOpenHandler(
            HeaderPanel.NotificationPanel
        );
        notificationConfig.panelSettings.onDismissed = getPanelDismissHandler(
            HeaderPanel.NotificationPanel
        );
    }

    const settingsConfig: FarRightSettingsPanelProps | undefined =
        farRightSettings?.settingsSettings;
    if (settingsConfig) {
        settingsConfig.panelSettings.onOpened = getPanelOpenHandler(
            HeaderPanel.SettingsPanel
        );
        settingsConfig.panelSettings.onDismissed = getPanelDismissHandler(
            HeaderPanel.SettingsPanel
        );
    }

    const helpConfig: FarRightSettingsPanelProps | undefined =
        farRightSettings?.helpSettings;
    if (helpConfig) {
        helpConfig.panelSettings.onOpened = getPanelOpenHandler(
            HeaderPanel.HelpPanel
        );
        helpConfig.panelSettings.onDismissed = getPanelDismissHandler(
            HeaderPanel.HelpPanel
        );
    }

    const feedbackConfig: FarRightFeedbackPanelProps | undefined =
        farRightSettings?.feedbackSettings;
    if (feedbackConfig) {
        const prevOnClick =
            farRightSettings?.feedbackSettings?.panelSettings.onClick;
        feedbackConfig.panelSettings.onClick = () => {
            if (!prevOnClick) return false;

            getPanelOpenHandler(HeaderPanel.FeedbackPanel);
            return prevOnClick();
        };
    }

    const profileConfig: FarRightProfilePanelProps = {
        ...farRightSettings?.profileSettings,
        panelSettings: {
            ...farRightSettings?.profileSettings?.panelSettings,
            logOutLink: 'javascript:void(0);',
            fullName: user?.name ?? '',
            emailAddress: user?.email ?? '',
            imageUrl: photo || undefined,
            onLogOut: handleLogOutClicked,
            onOpened: getPanelOpenHandler(HeaderPanel.ProfilePanel),
            onDismissed: getPanelDismissHandler(HeaderPanel.ProfilePanel),
            closeButtonAriaLabel: 'Close Profile',
        },
    };

    return (
        <Stack
            styles={{
                root: {
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: '10000',
                },
            }}
        >
            <CoherenceHeader
                {...otherProps}
                styles={{
                    dividerStyle: {
                        backgroundColor: CoherenceTheme.palette.themePrimary,
                    },
                    subComponentStyles: undefined,
                }}
                searchSettings={searchConfig}
                farRightSettings={{
                    ...farRightSettings,
                    notificationsSettings: notificationConfig,
                    settingsSettings: settingsConfig,
                    helpSettings: helpConfig,
                    feedbackSettings: feedbackConfig,
                    profileSettings: profileConfig,
                }}
            />
        </Stack>
    );
}
