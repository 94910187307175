import { IDefaultState } from '@employee-experience/common/lib/Models/IDefaultState';
import { IIconProps } from '@fluentui/react';
import { ILearningPathState } from './LearningPath.reducers';
import { ILearningPathCatalogItem } from './Models/LearningPath';
export interface ILearningPathInfoState extends IDefaultState {
    dynamic?: {
        LearningPathReducer: ILearningPathState;
    };
}

export enum LearningPathType {
    LearningPathGroup = 'LearningPathGroup',
    LearningPathItem = 'LearningPathItem',
}

export enum SelectionType {
    Sequential = 'Sequential',
    Random = 'Random',
    PickRule = 'Pick Rule',
}

export enum SectionType {
    Required = 'Required',
    Optional = 'Optional',
    Ignored = 'Ignored',
}

export enum StatusType {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Unknown = 'Unknown',
    ExpiringSoon = 'ExpiringSoon',
    Expired = 'Expired',
}

export enum CatalogLengthUnit {
    Page = 'pages',
    Seconds = 'seconds',
    WordCount = 'words',
}

export enum ServiceCatalogLengthUnit {
    Page = 'Page',
    Seconds = 'Seconds',
    WordCount = 'Word Count',
}

export enum ServiceLearningPathLengthUnit {
    Hours = 'Hours',
    Minutes = 'Minutes',
}

export type CatalogItemProperties = Pick<
    ILearningPathCatalogItem,
    'length' | 'url' | 'description' | 'name'
>;

export enum ElementTypeString {
    Section = 'section',
    Course = 'course',
}

export const catalogLengthUnitRecord: Record<
    ServiceCatalogLengthUnit,
    CatalogLengthUnit
> = {
    [ServiceCatalogLengthUnit.Page]: CatalogLengthUnit.Page,
    [ServiceCatalogLengthUnit.Seconds]: CatalogLengthUnit.Seconds,
    [ServiceCatalogLengthUnit.WordCount]: CatalogLengthUnit.WordCount,
};

export type StatusIconProps = Required<
    Pick<IIconProps, 'iconName' | 'aria-label' | 'style'>
>;
