import * as React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { LearningPathTreeView } from './LearningPathTree/LearningPathTreeView';
import { LearningPathView } from './LearningPathView';
import {
    IBreadcrumbItem,
    IconButton,
    Link,
    MessageBarType,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { useLearningPathState } from './useLearningPathState';
import {
    breadcrumbCollapsed,
    breadcrumbExpanded,
    navigationBreadcrumbStackStyles,
    getNavLearningPathStackStyles,
    breadcrumbStackStyles,
    navButtonStackStylesExpanded,
    navButtonStackStylesCollapsed,
} from './LearningPath.constants';
import { styleForMain } from '../Shared/constants';
import { CustomMessageBar } from '../Shared/Components/CustomMessageBar/CustomMessageBar';
import {
    resetGeneralMessage,
    setTreeNavigationExpanded,
} from './LearningPath.actions';
import {
    findLearningPathGroup,
    getBreadcrumbItems,
    getExpiredCoursesMessage,
    getExpiringCoursesMessage,
    getUrl,
} from './LearningPath.utils';
import { ILearningPathCatalogItem } from './Models/LearningPath';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { CoherenceBreadcrumb } from '@coherence-design-system/controls';
import { treeIconProps } from './LearningPathTree/LearningPathTree.constants';
import { AdminInfoView } from './AdminInfo/AdminInfoView';
import { useLearningPathExpiredSelector } from './LearningPathExpiredSelector';
import { StatusType } from './LearningPath.types';

export function LearningPathMainView(
    props: RouteComponentProps
): React.ReactElement {
    const {
        learningPath,
        isLoading,
        errorInfo,
        generalMessage,
        treeExpanded,
        selectedGroupId,
        queryStringParameters,
        isAdmin,
    } = useLearningPathState();
    const { expiredCourses, expiringCourses } =
        useLearningPathExpiredSelector();
    const { dispatch } = React.useContext(ReduxContext);
    const { learningPathId } = useParams<{ learningPathId: string }>();
    const selectedGroup = findLearningPathGroup(
        learningPath.parentGroup,
        selectedGroupId
    );
    const impersonateAlias =
        new URLSearchParams(props.location.search).get('alias') ?? '';
    const { history } = props;
    const breadcrumbItems: IBreadcrumbItem[] = getBreadcrumbItems(
        selectedGroup,
        learningPathId,
        queryStringParameters,
        history
    );

    const [showExpiredMessageBar, setShowExpiredMessageBar] =
        React.useState(false);
    const [showExpiringMessageBar, setShowExpiringMessageBar] =
        React.useState(false);
    const [pathComplete, setPathComplete] = React.useState(false);
    const [treeHeight, setTreeHeight] = React.useState(0);

    React.useEffect(() => {
        setPathComplete(learningPath.status === StatusType.Completed);
        if (expiredCourses.length > 0) {
            setShowExpiredMessageBar(true);
        }
        if (expiringCourses.length > 0) {
            setShowExpiringMessageBar(true);
        }
    }, [
        expiredCourses.length,
        expiringCourses.length,
        learningPath.parentGroup,
        learningPath.status,
    ]);

    const onClick = (course: ILearningPathCatalogItem) => {
        history.push(
            getUrl(learningPath.id, course.parent.id, queryStringParameters)
        );
    };

    return (
        <Stack
            verticalFill
            wrap={false}
            styles={{
                root: { marginTop: 48, minHeight: `${treeHeight}px` },
            }}
        >
            {!errorInfo?.showErrorPage && (
                <>
                    {!isLoading && isAdmin && (
                        <AdminInfoView
                            learningPath={learningPath}
                            alias={impersonateAlias}
                        />
                    )}
                    {generalMessage.message.length > 0 && (
                        <CustomMessageBar
                            message={<Stack>{generalMessage.message}</Stack>}
                            messageBarType={generalMessage.messageBarType}
                            onDismiss={(): void => {
                                dispatch(resetGeneralMessage());
                            }}
                        />
                    )}
                    {showExpiredMessageBar && (
                        <CustomMessageBar
                            message={getExpiredCoursesMessage(
                                expiredCourses.length,
                                pathComplete
                            )}
                            messageBarType={
                                pathComplete
                                    ? MessageBarType.warning
                                    : MessageBarType.error
                            }
                            onDismiss={(): void => {
                                setShowExpiredMessageBar(false);
                            }}
                            truncated={true}
                            extraContent={expiredCourses.map(
                                (course: ILearningPathCatalogItem) => (
                                    <p key={course.id}>
                                        <Link onClick={() => onClick(course)}>
                                            {course.name}
                                        </Link>
                                    </p>
                                )
                            )}
                        />
                    )}
                    {showExpiringMessageBar && (
                        <CustomMessageBar
                            message={getExpiringCoursesMessage(
                                expiringCourses.length,
                                pathComplete
                            )}
                            messageBarType={
                                pathComplete
                                    ? MessageBarType.warning
                                    : MessageBarType.severeWarning
                            }
                            onDismiss={(): void => {
                                setShowExpiringMessageBar(false);
                            }}
                            truncated={true}
                            extraContent={expiringCourses.map(
                                (course: ILearningPathCatalogItem) => (
                                    <p key={course.id}>
                                        <Link onClick={() => onClick(course)}>
                                            {course.name}
                                        </Link>
                                    </p>
                                )
                            )}
                        />
                    )}
                </>
            )}
            <Stack
                verticalFill
                styles={getNavLearningPathStackStyles(treeExpanded)}
            >
                {!isLoading &&
                    !errorInfo?.showErrorPage &&
                    learningPath.parentGroup.children.length > 0 && (
                        <Stack>
                            <Stack
                                horizontal
                                styles={navigationBreadcrumbStackStyles}
                            >
                                <Stack.Item
                                    styles={
                                        treeExpanded
                                            ? navButtonStackStylesExpanded
                                            : navButtonStackStylesCollapsed
                                    }
                                >
                                    <IconButton
                                        iconProps={treeIconProps}
                                        id={'treeButton'}
                                        ariaLabel="Open navigation"
                                        aria-expanded={treeExpanded}
                                        onClick={() => {
                                            dispatch(
                                                setTreeNavigationExpanded(
                                                    !treeExpanded
                                                )
                                            );
                                        }}
                                    ></IconButton>
                                </Stack.Item>
                                <Stack.Item
                                    styles={breadcrumbStackStyles(treeExpanded)}
                                >
                                    {breadcrumbItems.length > 1 && (
                                        <CoherenceBreadcrumb
                                            items={breadcrumbItems}
                                            styles={
                                                treeExpanded
                                                    ? breadcrumbExpanded
                                                    : breadcrumbCollapsed
                                            }
                                            onRenderItemContent={(
                                                item: IBreadcrumbItem
                                            ) => {
                                                if (item.text?.length > 24) {
                                                    return (
                                                        <TooltipHost
                                                            content={item.text}
                                                        >
                                                            {`${item.text.substring(
                                                                0,
                                                                24
                                                            )}...`}
                                                        </TooltipHost>
                                                    );
                                                }
                                                return <>{item.text}</>;
                                            }}
                                        />
                                    )}
                                </Stack.Item>
                            </Stack>
                            <nav style={{ zIndex: '2' }}>
                                <LearningPathTreeView
                                    history={props.history}
                                    reportHeight={setTreeHeight}
                                />
                            </nav>
                        </Stack>
                    )}

                <main id={'main'} tabIndex={-1} style={styleForMain}>
                    <LearningPathView {...props} />
                </main>
            </Stack>
        </Stack>
    );
}
