import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import * as React from 'react';
import { DescriptionType } from './LearningPath.constants';
import { LearningPathType, StatusType } from './LearningPath.types';
import { getChildrenElementType } from './LearningPath.utils';
import {
    ILearningPathCatalogItem,
    ILearningPathGroup,
    ILearningPathInfoState,
} from './Models/LearningPath';

export interface ILearningPathExpiredState {
    expiredCourses: ILearningPathCatalogItem[];
    expiringCourses: ILearningPathCatalogItem[];
}

export function useLearningPathExpiredSelector(): ILearningPathExpiredState {
    const useSelector = React.useContext(ReduxContext).useSelector;
    return useSelector((state: ILearningPathInfoState) => {
        const root =
            state.dynamic?.LearningPathReducer?.learningPath?.parentGroup;
        return {
            expiredCourses: getCoursesOfStatus(root, StatusType.Expired),
            expiringCourses: getCoursesOfStatus(root, StatusType.ExpiringSoon),
        };
    });
}

export function getCoursesOfStatus(
    root: ILearningPathGroup,
    targetStatus: StatusType
): ILearningPathCatalogItem[] {
    if (!root) {
        return [];
    }
    const addedCourses = new Map<string, ILearningPathCatalogItem>();
    const queue: Array<ILearningPathGroup> = [];
    queue.push(root);
    while (queue?.length) {
        // We use shift here to maintain tree order
        const currNode = queue.shift();
        const childrenType = getChildrenElementType(currNode);
        if (childrenType === DescriptionType.Course) {
            currNode.children.forEach((childCourse) => {
                if (
                    childCourse.status === targetStatus &&
                    childCourse.type === LearningPathType.LearningPathItem
                ) {
                    addedCourses.set(childCourse.id, childCourse);
                }
            });
        } else {
            currNode.children.forEach((childGroup) => {
                queue.push(childGroup as ILearningPathGroup);
            });
        }
    }
    const courses: ILearningPathCatalogItem[] = [];
    for (const key of Array.from(addedCourses.keys())) {
        courses.push(addedCourses.get(key));
    }
    return courses;
}
