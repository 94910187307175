import * as React from 'react';
import { Stack } from '@fluentui/react';
import {
    gradientStackStyles,
    gradientType,
} from './GradientBackground.constants';

export function GradientBackground(props: {
    depth: number;
}): React.ReactElement {
    return (
        <Stack
            styles={{
                ...gradientStackStyles,
            }}
        >
            <img
                src={`${__BASE_URL__}/assets/${
                    gradientType[props.depth] ?? 'blueGradient.svg'
                }`}
                width={'100%'}
                height={'272px'}
                role={'presentation'}
            />
        </Stack>
    );
}
