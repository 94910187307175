import {
    ILearningPath,
    ILearningPathGroup,
    ILearningPathGroupData,
    IServiceLearningPath,
    IServiceLearningPathGroupData,
    IServiceLearningPathElement,
    IServiceStatusInfo,
    IStatusInfo,
    ILearningPathCompletionMetadata,
    ILearningPathElement,
} from './Models/LearningPath';
import {
    CatalogItemProperties,
    catalogLengthUnitRecord,
    CatalogLengthUnit,
    LearningPathType,
    SelectionType,
    StatusType,
    SectionType,
} from './LearningPath.types';
import { cloneDeep } from 'lodash';
import { IServiceTargeting, ITargeting } from './Models/Targeting';
import { ICatalogInput, IServiceCatalogItem } from './Models/CatalogItem';
import { IBreadcrumbItem } from '@fluentui/react';
import { History } from 'history';
import { ErrorViewTitle, ServiceCallType } from '../Shared/constants';
import {
    learningPathElementType,
    emptyElement,
    ExpiryDate,
    serviceToClientStatus,
} from './LearningPath.constants';
import { updateParentReferences } from './LearningPathHelpers';
import { IErrorData } from '../Shared/types';

/**
 * Helper function that transforms group data from the service side to the client side.
 * @param clientGroupData
 * @returns group data structured for the service side
 */
function mapToClientGroupData(
    serviceGroupData: IServiceLearningPathGroupData
): ILearningPathGroupData {
    if (!serviceGroupData) return null;
    return {
        mandatory: serviceGroupData.isMandatory,
        sequenceEnforcement:
            serviceGroupData.criteria.selectionType ===
            SelectionType.Sequential,
        requiredItemCount: serviceGroupData.criteria.requiredScore,
    };
}

/**
 * Helper function that transforms the data of a service side item to a client side item.
 * @param clientElement
 * @returns Data structured for a client side learning path item.
 */
function mapToClientElement(
    parentGroup: ILearningPathGroup,
    serviceElement: IServiceLearningPathElement
): ILearningPathElement {
    if (!serviceElement) {
        return emptyElement;
    }
    const value: Pick<
        ILearningPathElement,
        'parent' | 'id' | 'name' | 'description'
    > = {
        parent: parentGroup,
        id: serviceElement.id.toLowerCase(),
        name: serviceElement.name,
        description: serviceElement.description ?? '',
    };
    const clientElement: ILearningPathElement = serviceElement.group
        ? {
              ...value,
              type: LearningPathType.LearningPathGroup,
              groupData: mapToClientGroupData(serviceElement.group),
              status: StatusType.Unknown,
              children: null,
              expiryDate: ExpiryDate.Unknown,
              ignoreCompletion: false,
              completionDate: '',
          }
        : {
              ...value,
              type: LearningPathType.LearningPathItem,
              sourceSystem: serviceElement.sourceSystem,
              status: StatusType.NotStarted,
              length: null,
              url: null,
              children: null,
              expiryDate: ExpiryDate.Unknown,
              ignoreCompletion: false,
              completionDate: '',
          };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    clientElement.children =
        clientElement.type === LearningPathType.LearningPathGroup
            ? mapToClientElements(clientElement, serviceElement.items)
            : [];
    return clientElement;
}

function mapToClientElements(
    parentGroup: ILearningPathGroup,
    serviceItems: IServiceLearningPathElement[]
): ILearningPathElement[] {
    return serviceItems?.map((item) => mapToClientElement(parentGroup, item));
}

/**
 * Helper function for transforming a learning path from the service side model to the client side model.
 * @param learningPath
 * @returns client learning path that has been converted from the service side structure.
 */
export const mapToClientLearningPath = (
    serviceLearningPath: IServiceLearningPath
): ILearningPath => {
    return {
        id: serviceLearningPath.id,
        name: serviceLearningPath.title,
        description: serviceLearningPath.description,
        // TODO: Update the below length conversion if unit is Hours from service.
        length: {
            hours: Math.floor(serviceLearningPath.length.value / 60),
            minutes: serviceLearningPath.length.value % 60,
        },
        isPublished: serviceLearningPath.isPublished,
        // mapToClientItem always returns a LearningPathGroup on the first call, so we cast in this special case
        parentGroup: mapToClientElement(
            null,
            serviceLearningPath.parentGroup
        ) as ILearningPathGroup,
        isActive: serviceLearningPath.isActive,
        imagePath: serviceLearningPath.imagePath,
        status: StatusType.NotStarted,
        assetOwner: serviceLearningPath.assetOwner ?? '',
        learningOrg: serviceLearningPath.learningOrg ?? '',
        modifiedTimeStamp: serviceLearningPath.versionInfo
            ? serviceLearningPath.versionInfo.ModifiedTimeStamp
            : serviceLearningPath.modifiedTimeStamp,
        modifiedStructureTimeStamp: serviceLearningPath.versionInfo
            ? serviceLearningPath.versionInfo.ModifiedStructureTimeStamp
            : serviceLearningPath.modifiedStructureTimeStamp,
    };
};
export function getCatalogInputs(
    root: ILearningPathElement
): Array<ICatalogInput> {
    const queue: Array<ILearningPathElement> = [];
    const catalogItems: Array<ICatalogInput> = [];
    queue.push(root);
    while (queue.length) {
        const currentNode = queue.pop();
        currentNode?.children.forEach((child: ILearningPathElement) => {
            if (child.type === LearningPathType.LearningPathItem) {
                catalogItems.push({
                    SourceSystem: child.sourceSystem,
                    ExternalId: child.id,
                });
            } else {
                queue.push(child);
            }
        });
    }
    return catalogItems;
}

export const mapToClientTargeting = (
    serviceTargeting: IServiceTargeting
): ITargeting => {
    return {
        assignedBy: serviceTargeting.AssignedBy,
        completeByDate: serviceTargeting.CompleteByDate,
        isActive: serviceTargeting.IsActive,
    };
};

export const mapToClientCatalogItems = (
    serviceCatalogItems: IServiceCatalogItem[]
): Map<string, CatalogItemProperties> => {
    const mappedCatalogItems = new Map();
    serviceCatalogItems.forEach((catalogItem: IServiceCatalogItem) => {
        const unit: CatalogLengthUnit =
            catalogLengthUnitRecord[catalogItem.Length?.Unit];
        mappedCatalogItems.set(catalogItem.ExternalId.toLowerCase(), {
            url: catalogItem.Url,
            length: {
                value: catalogItem.Length?.Value ?? 0,
                unit: unit ?? CatalogLengthUnit.Seconds,
            },
            description: catalogItem.Description,
            name: catalogItem.Title,
        });
    });
    return mappedCatalogItems;
};

export const mapToClientStatuses = (
    serviceStatuses: IServiceStatusInfo[]
): IStatusInfo[] => {
    const mappedStatuses: IStatusInfo[] = [];
    serviceStatuses.forEach((serviceStatus: IServiceStatusInfo) => {
        mappedStatuses.push({
            id: serviceStatus.Id.toLowerCase(),
            status:
                serviceToClientStatus[serviceStatus.Status] ??
                StatusType.Unknown,

            expiryDate: serviceStatus.ExpiryDate ?? ExpiryDate.None,
            ignoreCompletion: serviceStatus.IsIgnored ?? false,
            completionDate: serviceStatus.CompletionDate ?? '',
        });
    });
    return mappedStatuses;
};
export function updateStatuses(
    statuses: IStatusInfo[],
    learningPath: ILearningPath
): ILearningPath {
    const clonedLearningPath = cloneDeep(learningPath);
    const queue: Array<ILearningPathElement> = [];
    queue.push(clonedLearningPath.parentGroup);
    while (queue.length) {
        const currNode = queue.pop();
        if (currNode.type === LearningPathType.LearningPathGroup) {
            const lowerCaseID = currNode.id.toLowerCase();
            const status = statuses.find((group) => group.id === lowerCaseID);
            if (status) {
                currNode.status = status.status;
                currNode.expiryDate = status.expiryDate;
                currNode.ignoreCompletion = status.ignoreCompletion ?? false;
                currNode.completionDate = status.completionDate;
            }
        } else {
            // In order to determine which status belongs to an identical item we find the closest item to the parent group
            const lowerCaseParentID = currNode.parent.id.toLowerCase();
            const index = statuses.findIndex(
                (group) => group.id.toLowerCase() === lowerCaseParentID
            );
            const lowerCaseID = currNode.id.toLowerCase();
            let found = false;
            for (let i = index + 1; !found && i < statuses.length; i++) {
                if (statuses[i].id.toLowerCase() === lowerCaseID) {
                    found = true;
                    currNode.completionDate = statuses[i].completionDate;
                    currNode.expiryDate = statuses[i].expiryDate;
                    currNode.ignoreCompletion = statuses[i].ignoreCompletion;
                    currNode.status = statuses[i].status;
                }
            }
        }
        currNode.children.forEach((e: ILearningPathElement) => {
            queue.push(e);
        });
    }
    const lpStatus = statuses.find(
        (element) => element.id === clonedLearningPath.id
    );
    clonedLearningPath.status = lpStatus.status;
    clonedLearningPath.parentGroup.completionDate = lpStatus.completionDate;
    return clonedLearningPath;
}

export function updateCatalogItemProperties(
    catalogMap: Map<string, CatalogItemProperties>,
    learningPath: ILearningPath
): ILearningPath {
    const clonedLearningPath = cloneDeep(learningPath);
    const queue: Array<ILearningPathElement> = [];
    queue.push(clonedLearningPath.parentGroup);
    while (queue.length) {
        const currNode = queue.pop();
        if (currNode.type === LearningPathType.LearningPathItem) {
            const currentId: string = currNode.id.toLowerCase();
            currNode.length = catalogMap.get(currentId)?.length ?? null;
            currNode.url = catalogMap.get(currentId).url;
            currNode.description = catalogMap.get(currentId).description;
            currNode.name = catalogMap.get(currentId).name;
        }
        currNode.children.forEach((o) => {
            queue.push(o);
        });
    }
    return clonedLearningPath;
}

/**
 * Helper function for getting breadcrumb items based on selected group.
 * @returns Breadcrumb items.
 */
export const getBreadcrumbItems = (
    selectedGroup: ILearningPathGroup,
    lpId: string,
    queryStringParams: string | null,
    history: History<unknown>
): IBreadcrumbItem[] => {
    let items: IBreadcrumbItem[] = [];
    let isSelectedGroup = true;
    while (selectedGroup) {
        const tempSelectedGroup = selectedGroup;
        const name = tempSelectedGroup.name;
        items.push(
            isSelectedGroup
                ? {
                      text: name,
                      key: name,
                  }
                : {
                      text: name,
                      key: name,
                      onClick: (): void => {
                          if (tempSelectedGroup.parent) {
                              history.push(
                                  `/${lpId}/${tempSelectedGroup.id}${
                                      queryStringParams ?? ''
                                  }`
                              );
                          } else {
                              history.push(
                                  `/${lpId}${queryStringParams ?? ''}`
                              );
                          }
                      },
                  }
        );
        selectedGroup = selectedGroup.parent;
        isSelectedGroup = false;
    }
    items = items.reverse();
    return items;
};

/**
 * Helper function to check if the tree item, section, courses can be enabled based on sequence enforcement flag
 * @param root -- array of learning path elements
 * @param isSequenceEnabled -- boolean flag from input if the sequence enforcement is checked for the selected group
 * @returns array of boolean values.
 */
export function checkEnableDisableForAll(
    root: Pick<ILearningPathElement, 'status'>[],
    isSequenceEnabled: boolean
): boolean[] {
    const boolArray: Array<boolean> = [];
    let foundDisabled = false;
    if (!isSequenceEnabled) return Array(root.length).fill(true);
    root.map((child, index) => {
        if (index == 0) {
            boolArray.push(true);
        } else if (foundDisabled) {
            boolArray.push(false);
        } else if (
            index > 0 &&
            (root[index - 1].status == StatusType.Completed ||
                root[index - 1].status == StatusType.ExpiringSoon)
        ) {
            boolArray.push(true);
        } else {
            foundDisabled = true;
            boolArray.push(false);
        }
    });
    return boolArray;
}
/***
 * Helper function to return a boolean value based on params
 * this is used to decide to enable the course link and corresponding styles
 */
export function checkIsIgnoredOrIsEnabled(
    ignoreCompletion: boolean,
    isEnabled: boolean
): boolean {
    return ignoreCompletion || !isEnabled;
}
/**
 * Helper function for finding group in learning path based on groupId provided.
 * @param root
 * @param groupId
 * @returns Group with matching groupId or undefined.
 */
export function findLearningPathGroup(
    root: ILearningPathGroup,
    groupId: string
): ILearningPathGroup {
    if (!groupId) return undefined;
    const queue: Array<ILearningPathElement> = [];
    queue.push(root);
    while (queue.length) {
        const currNode = queue.pop();
        if (
            currNode.id === groupId &&
            currNode.type === LearningPathType.LearningPathGroup
        ) {
            return currNode;
        }
        currNode.children.forEach((o) => {
            queue.push(o);
        });
    }
    return undefined;
}

/**
 * Helper function for calculating the amount of child items that have been completed in a group.
 * @returns Amount of completed  child items (this can be either a course or a section).
 */
export function getCompletedItemsCount(root: ILearningPathElement): number {
    if (!root) return 0;
    let numCompleted = 0;
    let hasMandatorySiblings = false;
    root.children.forEach((element) => {
        if (element.groupData?.mandatory) {
            hasMandatorySiblings = true;
        }
    });
    root.children.forEach((element) => {
        if (
            element.status === StatusType.Completed &&
            !element.ignoreCompletion &&
            (element.type === LearningPathType.LearningPathItem ||
                !hasMandatorySiblings ||
                element.groupData?.mandatory)
        ) {
            numCompleted++;
        }
    });
    return numCompleted;
}

/**
 * Determines whether to show the error page.
 * Shows error page if user makes an admin-level request
 * but doesn't have admin permissions.
 * Shows error page if request is for learning path structure or catalog item details,
 * as those are necessary to render the app.
 * Else returns `false` to indicate nothing went wrong.
 */
export function showErrorPageBasedOnServiceCall(
    serviceCall: ServiceCallType,
    isAdminRequest = false,
    statusCode = 0
): boolean {
    if (isAdminRequest && (statusCode === 403 || statusCode === 401)) {
        return true;
    }
    return (
        serviceCall === ServiceCallType.LearningPath ||
        serviceCall === ServiceCallType.CatalogItems
    );
}

export function getErrorTitle(
    serviceCall: ServiceCallType,
    statusCode: number
): string {
    if (statusCode === 404) {
        return `${
            serviceCall === ServiceCallType.LearningPath
                ? 'Learning path'
                : 'Catalog information'
        } could not be found.`;
    } else {
        return ErrorViewTitle[statusCode];
    }
}

export function getUrl(
    learningPathId: string,
    groupId: string | null,
    queryStringParameters: string | null
): string {
    let result = `/${learningPathId}`;
    if (groupId) result += `/${groupId}`;
    result += queryStringParameters ?? '';
    return result;
}

export function updateAllStatusesToType(
    learningPath: ILearningPath,
    status: StatusType
): ILearningPath {
    const clonedLearningPath = cloneDeep(learningPath);
    const queue: Array<ILearningPathElement> = [];
    queue.push(clonedLearningPath.parentGroup);
    while (queue.length) {
        const currNode = queue.pop();
        currNode.status = status;
        currNode.children.forEach((e: ILearningPathElement) => {
            queue.push(e);
        });
    }
    clonedLearningPath.status = status;
    return {
        ...clonedLearningPath,
        parentGroup: updateParentReferences(clonedLearningPath.parentGroup),
    };
}

export function getCompletionMessage(group: ILearningPathGroup): string {
    const { requiredCount, requiredSectionsCount } =
        getCompletionMetadata(group);
    const childElementType = getChildrenElementType(group);
    if (requiredSectionsCount) {
        return `Complete ${requiredCount} ${
            requiredCount > 1 ? 'sections' : 'section'
        } to receive credit.`;
    } else {
        return `Complete ${requiredCount} ${childElementType}${
            requiredCount > 1 ? 's' : ''
        } to receive credit.`;
    }
}

/**
 * Returns instructions shown to the user about requirements to complete this section.
 * e.g. `These 4 sections must be completed`, `Optional courses`, etc.
 */
export function getSectionCompletionText(
    children: ILearningPathElement[],
    sectionType?: SectionType
): string {
    const hasMultipleChildren = children.length > 1;
    const totalChildrenCount = children.length;
    const requiredChildrenCount = getCompletionMetadata(
        children[0]?.parent
    ).requiredCount;
    const childType = learningPathElementType[children[0]?.type];
    return `${
        sectionType
            ? `${
                  sectionType === SectionType.Required
                      ? `${
                            hasMultipleChildren
                                ? `These ${totalChildrenCount} ${childType}s`
                                : `This ${childType}`
                        } must be completed`
                      : sectionType === SectionType.Ignored
                      ? `${totalChildrenCount} previously completed ${childType}${
                            hasMultipleChildren ? 's' : ''
                        }`
                      : `Optional ${childType}${hasMultipleChildren ? 's' : ''}`
              }`
            : `${
                  hasMultipleChildren
                      ? `${
                            requiredChildrenCount === totalChildrenCount
                                ? `These ${totalChildrenCount}`
                                : `${requiredChildrenCount} of these ${totalChildrenCount}`
                        } ${childType}s must be completed`
                      : `This ${childType} must be completed`
              }`
    }`;
}

export function getCompletionMetadata(
    group: ILearningPathGroup | undefined | null
): ILearningPathCompletionMetadata | undefined {
    if (!group) {
        return undefined;
    }
    return {
        requiredCount: group.groupData.requiredItemCount,
        totalCount: group.children.filter(
            (element) => !element.ignoreCompletion
        ).length,
        requiredSectionsCount: group.children.filter(
            (element) => element.groupData?.mandatory
        ).length,
    };
}

export function getChildrenElementType(group: ILearningPathElement): string {
    return learningPathElementType[group?.children[0]?.type] ?? 'None';
}

export function isGroupCompleted(
    completedCount: number,
    requiredCount: number
): boolean {
    return completedCount > 0 && completedCount >= requiredCount;
}

export const getRenewalMessage = (children: ILearningPathElement[]): string => {
    const expiredItems = children.filter(
        (item) => item.status === StatusType.Expired
    );
    const remainingItems = children.filter(
        (item) =>
            item.status !== StatusType.Expired &&
            item.status !== StatusType.Completed
    );
    if (!expiredItems.length) {
        return '';
    }
    return `To renew credit, retake the expired course${
        expiredItems.length > 1 ? 's' : ''
    } ${
        remainingItems.length > 0 ? 'or choose a different course ' : ''
    }below.`;
};

export function getExpiredCoursesMessage(
    count: number,
    pathComplete: boolean
): string {
    return `${
        pathComplete
            ? 'You have a cert that has expired.'
            : 'Your credit for this learning path has expired.'
    } To renew, complete the ${count} expired course${count > 1 ? 's' : ''}.`;
}

export function getExpiringCoursesMessage(
    count: number,
    pathComplete: boolean
): string {
    const plural = count > 1;
    return `${count} course${plural ? 's' : ''} ${plural ? 'are' : 'is'}${
        pathComplete
            ? ' due for renewal.'
            : ` about to expire. Renew ${
                  plural ? 'them' : 'it'
              } now to retain credit for this learning path.`
    }`;
}

export function isExpiry(status: StatusType): boolean {
    return status === StatusType.Expired || status === StatusType.ExpiringSoon;
}

/** Returns true iff at least one section is required and at least one section is optional */
export function hasBothRequiredAndOptional(
    completionMetadata: ILearningPathCompletionMetadata | undefined
): boolean {
    return (
        !!completionMetadata?.requiredSectionsCount &&
        completionMetadata.requiredSectionsCount < completionMetadata.totalCount
    );
}

export function hasIgnoredChildren(group: ILearningPathGroup): boolean {
    return group.children.filter((item) => item.ignoreCompletion).length > 0;
}

export function ignoreElementMessage(
    length: number,
    type: LearningPathType
): string {
    return `Your previously completed ${learningPathElementType[type]}${
        length > 1 ? 's are' : ' is'
    } in this section. Learn something new this time, choose one of the other options above`;
}

export function getInvalidAliasErrorMessage(
    exData: IErrorData | string
): string {
    if (typeof exData === 'string') return exData;
    return exData?.ValidationResults[0]?.propertyValidationResult[0]?.MemberName?.toLowerCase() ===
        'alias'
        ? exData.ValidationResults[0].propertyValidationResult[0].Message ?? ''
        : '';
}

export function learningPathChangedMessage(
    completionDate: string,
    modifiedTimeStamp: string,
    modifiedStructureTimeStamp: string
): string {
    //modifiedStructureTimeStamp will be null if publishing occurred before we began tracking changes
    const preferredModifiedTimeStamp =
        modifiedStructureTimeStamp ?? modifiedTimeStamp;

    if (!completionDate || !preferredModifiedTimeStamp) return '';

    const completedDate = new Date(completionDate);
    const modifyDate = new Date(preferredModifiedTimeStamp);

    if (modifyDate > completedDate) {
        return 'This learning path has changed since you completed it. Your completion is still valid.';
    }
    return '';
}
