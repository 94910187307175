import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Stack, ThemeProvider } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { ShellStyles } from '@employee-experience/common/lib/Components/Shell/Shell.styled';
import { ShellWithStore } from './ShellWithStore';
import { Routes } from './Routes';
import { useHeaderConfig } from './useHeaderConfig';
import { PrivacyFooter } from './Pages/Shared/Components/PrivacyFooter/PrivacyFooter';
import { useLearningPathState } from './Pages/LearningPath/useLearningPathState';
import { Header } from './Components/Header/Header';
import { heightClassName } from './Pages/Shared/constants';

function App(): React.ReactElement {
    useLoginOnStartup(__IS_INTEGRATION_TESTING__ === 'true' ? false : true);
    const { learningPath } = useLearningPathState();
    const headerConfig = useHeaderConfig(learningPath.name, learningPath.id);

    return (
        <ThemeProvider
            theme={CoherenceTheme}
            applyTo="body"
            className={heightClassName.fullHeight}
        >
            <ShellStyles />
            <BrowserRouter>
                <Header {...headerConfig} />
                <Stack verticalFill style={{ top: 48 }}>
                    <Stack
                        styles={{
                            root: {
                                flexGrow: 1,
                                top: 48,
                                backgroundColor:
                                    CoherenceTheme.palette.neutralLighter,
                            },
                        }}
                    >
                        <Routes />
                    </Stack>
                    <PrivacyFooter />
                </Stack>
            </BrowserRouter>
        </ThemeProvider>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
