import {
    ILearningPath,
    IServiceLearningPathElement,
    IStatusInfo,
} from './Models/LearningPath';
import { CatalogItemProperties } from './LearningPath.types';
import { ITargeting } from './Models/Targeting';
import { ICatalogInput } from './Models/CatalogItem';
import { IErrorMessage } from '../Shared/types';
import { ServiceCallType } from '../Shared/constants';

export enum LearningPathActionType {
    RequestLearningPath = 'RequestLearningPath',
    ReceiveLearningPath = 'ReceiveLearningPath',
    ReceiveCatalogItems = 'ReceiveCatalogItems',
    ReceiveTargeting = 'ReceiveTargeting',
    RequestCatalogItems = 'RequestCatalogItems',
    RequestTargeting = 'RequestTargeting',
    RequestStatuses = 'RequestStatuses',
    ReceiveStatuses = 'ReceiveStatuses',
    FailLearningPathRequest = 'FailLearningPathRequest',
    SetSelectedGroupId = 'SetSelectedGroupId',
    RequestMetadata = 'RequestMetadata',
    SetQueryStringParameters = 'SetQueryStringParameters',
    FailedStatusesRequest = 'FailedStatusesRequest',
    ResetGeneralMessage = 'ResetGeneralMessage',
    UpdateLoading = 'UpdateLoading',
    SetTreeNavigationExpanded = 'SetTreeNavigationExpanded',
}

export interface IRequestLearningPath {
    type: LearningPathActionType.RequestLearningPath;
    id: string;
    groupId: string;
    isPublished: boolean;
    isDefaultAction: boolean;
    alias: string;
}
export interface IRequestMetadata {
    type: LearningPathActionType.RequestMetadata;
    id: string;
    serviceParentGroup: IServiceLearningPathElement;
    hash: unknown;
    catalogInputs: ICatalogInput[];
    isDefaultAction: boolean;
    isPublished: boolean;
    alias: string;
}

/**
 * 'items' here are from the LearningPath tree.
 */
export interface IRequestCatalogItems {
    type: LearningPathActionType.RequestCatalogItems;
    items: ICatalogInput[];
}

export interface IRequestTargeting {
    type: LearningPathActionType.RequestTargeting;
    id: string;
    alias: string;
}

export interface IRequestStatuses {
    type: LearningPathActionType.RequestStatuses;
    id: string;
    hash: unknown;
    serviceParentGroup: IServiceLearningPathElement;
    alias: string;
}

export interface IReceiveLearningPath {
    type: LearningPathActionType.ReceiveLearningPath;
    groupId: string;
    learningPath: ILearningPath;
}

export interface IReceiveCatalogItems {
    type: LearningPathActionType.ReceiveCatalogItems;
    items: Map<string, CatalogItemProperties>;
}

export interface IReceiveTargeting {
    type: LearningPathActionType.ReceiveTargeting;
    targeting: ITargeting;
    isAdmin?: boolean;
}

export interface IReceiveStatuses {
    type: LearningPathActionType.ReceiveStatuses;
    mappedStatuses: IStatusInfo[];
    isAdmin?: boolean;
}

export interface IFailLearningPathRequest {
    type: LearningPathActionType.FailLearningPathRequest;
    message: IErrorMessage;
    serviceCall: ServiceCallType;
    isAdmin: boolean;
}
export interface ISetSelectedGroupId {
    type: LearningPathActionType.SetSelectedGroupId;
    selectedGroupId: string;
}

export interface ISetQueryStringParameters {
    type: LearningPathActionType.SetQueryStringParameters;
    queryStringParameters: string;
}

export interface IFailedStatusesRequest {
    type: LearningPathActionType.FailedStatusesRequest;
    isAdmin: boolean;
    errorMessage: string;
}

export interface IResetGeneralMessage {
    type: LearningPathActionType.ResetGeneralMessage;
}
export interface IUpdateLoading {
    type: LearningPathActionType.UpdateLoading;
    isLoading: boolean;
}

export interface ISetTreeNavigationExpanded {
    type: LearningPathActionType.SetTreeNavigationExpanded;
    isExpanded: boolean;
}

export function requestLearningPath(
    id: string,
    groupId: string,
    isPublished: boolean,
    isDefaultAction: boolean,
    alias: string
): IRequestLearningPath {
    return {
        type: LearningPathActionType.RequestLearningPath,
        id,
        groupId,
        isPublished,
        isDefaultAction,
        alias,
    };
}

export function requestMetadata(
    id: string,
    serviceParentGroup: IServiceLearningPathElement,
    hash: unknown,
    catalogInputs: ICatalogInput[],
    isDefaultAction: boolean,
    isPublished: boolean,
    alias: string
): IRequestMetadata {
    return {
        type: LearningPathActionType.RequestMetadata,
        id,
        serviceParentGroup,
        hash,
        catalogInputs,
        isDefaultAction,
        isPublished,
        alias,
    };
}

export function receiveLearningPath(
    learningPath: ILearningPath,
    groupId: string
): IReceiveLearningPath {
    return {
        type: LearningPathActionType.ReceiveLearningPath,
        learningPath,
        groupId,
    };
}

export function receiveCatalogItems(
    items: Map<string, CatalogItemProperties>
): IReceiveCatalogItems {
    return {
        type: LearningPathActionType.ReceiveCatalogItems,
        items,
    };
}

export function receiveTargeting(
    targeting: ITargeting,
    isAdmin = false
): IReceiveTargeting {
    return {
        type: LearningPathActionType.ReceiveTargeting,
        targeting,
        isAdmin,
    };
}

export function receiveStatuses(
    mappedStatuses: IStatusInfo[],
    isAdmin?: boolean
): IReceiveStatuses {
    return {
        type: LearningPathActionType.ReceiveStatuses,
        mappedStatuses,
        isAdmin,
    };
}

export function failLearningPathRequest(
    message: IErrorMessage,
    serviceCall: ServiceCallType,
    isAdmin = false
): IFailLearningPathRequest {
    return {
        type: LearningPathActionType.FailLearningPathRequest,
        message,
        serviceCall,
        isAdmin,
    };
}

export function setSelectedGroupId(
    selectedGroupId: string
): ISetSelectedGroupId {
    return {
        type: LearningPathActionType.SetSelectedGroupId,
        selectedGroupId,
    };
}

export function setQueryStringParameters(
    queryStringParameters: string
): ISetQueryStringParameters {
    return {
        type: LearningPathActionType.SetQueryStringParameters,
        queryStringParameters,
    };
}

export function failedStatusesRequest(
    isAdmin: boolean,
    errorMessage = ''
): IFailedStatusesRequest {
    return {
        type: LearningPathActionType.FailedStatusesRequest,
        isAdmin,
        errorMessage,
    };
}

export function resetGeneralMessage(): IResetGeneralMessage {
    return {
        type: LearningPathActionType.ResetGeneralMessage,
    };
}

export function updateLoading(isLoading: boolean): IUpdateLoading {
    return {
        type: LearningPathActionType.UpdateLoading,
        isLoading,
    };
}

export function setTreeNavigationExpanded(
    isExpanded: boolean
): ISetTreeNavigationExpanded {
    return {
        type: LearningPathActionType.SetTreeNavigationExpanded,
        isExpanded,
    };
}
