import * as React from 'react';
import { Stack } from '@fluentui/react';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import { styleForHomeMessage } from './HomePage.styles';

export function HomePage(): React.ReactElement {
    usePageTitle(`${__APP_NAME__}`);
    const greyBackgroundDiv = document.getElementById(
        'greyBackgroundComponent'
    );

    React.useEffect(() => {
        if (greyBackgroundDiv !== null) {
            greyBackgroundDiv.style.display = 'none';
        }
    }, [greyBackgroundDiv]);
    return (
        <Stack
            verticalAlign={'center'}
            horizontalAlign={'center'}
            grow
            verticalFill
        >
            <h1 style={styleForHomeMessage}>
                Please browse the respective learning path url to view the
                content
            </h1>
        </Stack>
    );
}
