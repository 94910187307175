import {
    getLearningPathInitialState,
    ILearningPathState,
} from './LearningPath.reducers';
import * as React from 'react';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { ILearningPathInfoState } from './Models/LearningPath';

export function useLearningPathState(): ILearningPathState {
    const useSelector = React.useContext(ReduxContext).useSelector;
    return useSelector(
        (state: ILearningPathInfoState) =>
            state.dynamic?.LearningPathReducer ?? getLearningPathInitialState()
    );
}
