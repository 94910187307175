import {
    IStyleFunctionOrObject,
    ILinkStyleProps,
    ILinkStyles,
} from '@fluentui/react';

/**
 * Distinguishes links from nearby plaintext.
 * See __REFERENCE__
 */
export const linkNearTextStyle: IStyleFunctionOrObject<
    ILinkStyleProps,
    ILinkStyles
> = {
    root: {
        textDecoration: 'underline',
    },
};
