import { CoherenceTheme } from '@coherence-design-system/styles';
import { IStackStyles } from '@fluentui/react';
import { CSSProperties } from 'styled-components';

export const completedIconStyles: IStackStyles = {
    root: {
        fontSize: 20,
        fontWeight: 600,
        color: CoherenceTheme.palette.white,
        marginBottom: '10px',
    },
};

export const progressIndicatorStackStyles: CSSProperties = {
    height: '40px',
    width: '40px',
};
