import {
    getContext,
    put,
    call,
    takeLatest,
    all,
    SimpleEffect,
    StrictEffect,
} from 'redux-saga/effects';
import {
    IHttpClient,
    IHttpClientResult,
} from '@employee-experience/common/lib/Models';
import {
    LearningPathActionType,
    IRequestLearningPath,
    receiveLearningPath,
    receiveCatalogItems,
    receiveTargeting,
    IRequestMetadata,
    requestMetadata,
    failedStatusesRequest,
    receiveStatuses,
    failLearningPathRequest,
    IRequestCatalogItems,
    IRequestTargeting,
    IRequestStatuses,
    updateLoading,
} from './LearningPath.actions';
import {
    getCatalogInputs,
    getInvalidAliasErrorMessage,
    mapToClientLearningPath,
    mapToClientStatuses,
    mapToClientTargeting,
} from './LearningPath.utils';
import { mapToClientCatalogItems } from './LearningPath.utils';
import { IServiceCatalogItem } from './Models/CatalogItem';
import {
    genericErrorMessage,
    learningPathHashHeader,
    learningPathNotTargeted,
} from './LearningPath.constants';
import {
    IServiceLearningPath,
    IServiceStatusInfo,
} from './Models/LearningPath';
import { IServiceTargeting } from './Models/Targeting';
import { IErrorMessage } from '../Shared/types';
import { ServiceCallType } from '../Shared/constants';

export function* fetchLearningPathSaga(
    action: IRequestLearningPath
): IterableIterator<SimpleEffect<string, unknown>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const isAliasProvided = !!action.alias?.length;
    // For learners we need to call: /api/v1/LearningPaths/${LPID}/view
    // For admins preview mode we need to call: /api/v1/LearningPaths/{LPID}?isPublished={true/false}
    // For impersonation we need to call: /api/v1/LearningPaths/{LPID}?isPublished={true}
    const url = action.isDefaultAction
        ? isAliasProvided
            ? `${__LEARNING_PATH_SERVICE_API_BASE_URL__}/api/v1/LearningPaths/${action.id}?isPublished=true`
            : `${__LEARNING_PATH_SERVICE_API_BASE_URL__}/api/v1/LearningPaths/${action.id}/view`
        : `${__LEARNING_PATH_SERVICE_API_BASE_URL__}/api/v1/LearningPaths/${action.id}?isPublished=${action.isPublished}`;
    try {
        const {
            data: response,
            headers: responseHeaders,
        }: IHttpClientResult<IServiceLearningPath> = yield call(
            [httpClient, httpClient.request],
            {
                method: 'get',
                url: url,
                resource: __LEARNING_PATH_SERVICE_RESOURCE_ID__,
            }
        );
        const clientLearningPath = mapToClientLearningPath(response);
        yield put(receiveLearningPath(clientLearningPath, action.groupId));
        const learningPathHash = responseHeaders[learningPathHashHeader];
        const catalogInputs = getCatalogInputs(clientLearningPath.parentGroup);

        // RE `requestMetaData(/*...*/ response.parentGroup)`:
        // This is ok to skip the transformation from server structure to client structure because it is a pass-through action.
        // The service expects the values to be structured exactly as they are stored on the service side so it makes sense to avoid
        // any transformation and immediately pass the id and parentGroup from the response as parameters to the rollup API call.
        if (response.parentGroup) {
            yield put(
                requestMetadata(
                    clientLearningPath.id,
                    response.parentGroup,
                    learningPathHash,
                    catalogInputs,
                    action.isDefaultAction,
                    action.isPublished,
                    action.isDefaultAction && isAliasProvided
                        ? action.alias
                        : ''
                )
            );
        }
    } catch (ex) {
        yield put(
            failLearningPathRequest(
                ex as IErrorMessage,
                ServiceCallType.LearningPath
            )
        );
    }
}

export function* fetchPathSaga(
    action: IRequestLearningPath
): IterableIterator<StrictEffect<string, unknown>> {
    try {
        if (
            action.isDefaultAction &&
            (action.isPublished || !!action.alias?.length)
        ) {
            yield all([
                call(fetchLearningPathSaga, action),
                call(fetchTargeting, {
                    type: LearningPathActionType.RequestTargeting,
                    id: action.id,
                    alias: action.alias,
                }),
            ]);
        } else {
            yield call(fetchLearningPathSaga, action);
        }
    } catch {}
}

export function* fetchMetadataSaga(
    action: IRequestMetadata
): IterableIterator<StrictEffect<string, unknown>> {
    try {
        if (
            (action.isDefaultAction && action.isPublished) ||
            !!action.alias?.length
        ) {
            yield all([
                call(fetchCatalogItems, {
                    type: LearningPathActionType.RequestCatalogItems,
                    items: action.catalogInputs,
                }),
                call(fetchStatuses, {
                    type: LearningPathActionType.RequestStatuses,
                    id: action.id,
                    serviceParentGroup: action.serviceParentGroup,
                    hash: action.hash,
                    alias: action.alias,
                }),
            ]);
        } else {
            yield all([
                call(fetchCatalogItems, {
                    type: LearningPathActionType.RequestCatalogItems,
                    items: action.catalogInputs,
                }),
            ]);
        }
        yield put(updateLoading(false));
    } catch (ex) {
        yield put(
            failLearningPathRequest(
                ex as IErrorMessage,
                ServiceCallType.CatalogItems
            )
        );
    }
}

export function* fetchCatalogItems(
    action: IRequestCatalogItems
): IterableIterator<SimpleEffect<string, unknown>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const url = `${__CATALOG_SERVICE_API_BASE_URL__}/api/v1/Catalog/get`;
    try {
        const {
            data: catalogResponse,
        }: IHttpClientResult<IServiceCatalogItem[]> = yield call(
            [httpClient, httpClient.request],
            {
                method: 'post',
                url: url,
                resource: __CATALOG_SERVICE_RESOURCE_ID__,
                data: action.items,
            }
        );
        yield put(
            receiveCatalogItems(mapToClientCatalogItems(catalogResponse))
        );
    } catch (ex) {
        yield put(
            failLearningPathRequest(
                ex as IErrorMessage,
                ServiceCallType.CatalogItems
            )
        );
    }
}

// Needs to accept a single param to be compatible with recordSaga
export function* fetchStatuses(
    action: IRequestStatuses
): IterableIterator<SimpleEffect<string, unknown>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const aliasProvided = !!action.alias?.length;
    const url = aliasProvided
        ? `${__LEARNER_SERVICE_API_BASE_URL__}/api/v1/LearningPath/${action.id}/Impersonate?alias=${action.alias}`
        : `${__LEARNER_SERVICE_API_BASE_URL__}/api/v1/LearningPath/${action.id}`;
    try {
        const { data: response }: IHttpClientResult<IServiceStatusInfo[]> =
            yield call([httpClient, httpClient.request], {
                method: 'post',
                url: url,
                resource: __LEARNER_SERVICE_RESOURCE_ID__,
                data: action.serviceParentGroup,
                headers: {
                    [learningPathHashHeader]: action.hash as string,
                },
            });
        yield put(
            receiveStatuses(mapToClientStatuses(response), aliasProvided)
        );
    } catch (ex) {
        if ((ex as IErrorMessage).status === 400) {
            yield put(
                failedStatusesRequest(
                    aliasProvided,
                    getInvalidAliasErrorMessage((ex as IErrorMessage).data)
                )
            );
        } else {
            yield put(failedStatusesRequest(aliasProvided));
        }
        yield put(
            failLearningPathRequest(
                ex as IErrorMessage,
                ServiceCallType.Statuses,
                aliasProvided
            )
        );
    }
}

export function* fetchTargeting(
    action: IRequestTargeting
): IterableIterator<SimpleEffect<string, unknown>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const aliasProvided = !!action.alias?.length;
    const url = aliasProvided
        ? `${__LEARNER_SERVICE_API_BASE_URL__}/api/v1/Roadmap/Targeting/LearningAdminCentral/${action.id}/Impersonate?alias=${action.alias}`
        : `${__LEARNER_SERVICE_API_BASE_URL__}/api/v1/Roadmap/Targeting/LearningAdminCentral/${action.id}`;
    try {
        const {
            data: targetingResponse,
        }: IHttpClientResult<IServiceTargeting> = yield call(
            [httpClient, httpClient.request],
            {
                method: 'get',
                url: url,
                resource: __LEARNER_SERVICE_RESOURCE_ID__,
            },
            {
                silentError: true,
            }
        );
        yield put(
            receiveTargeting(
                mapToClientTargeting(targetingResponse),
                aliasProvided
            )
        );
    } catch (ex) {
        const _ex: IErrorMessage = ex as IErrorMessage;
        _ex.statusText =
            _ex.status === 404 ? learningPathNotTargeted : genericErrorMessage;
        yield put(
            failLearningPathRequest(
                _ex,
                ServiceCallType.Targeting,
                aliasProvided
            )
        );
    }
}
export function* learningPathSagas(): IterableIterator<unknown> {
    yield all([
        takeLatest(LearningPathActionType.RequestLearningPath, fetchPathSaga),
    ]);
    yield all([
        takeLatest(LearningPathActionType.RequestMetadata, fetchMetadataSaga),
    ]);
}
