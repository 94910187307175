import * as React from 'react';
import { Link } from '@fluentui/react';
import { CSSProperties } from 'styled-components';
import { CardType, collapsedDescriptionHeight } from '../../constants';
import { truncatedElementCardDescriptionStyles } from '../../../LearningPath/LearningPath.styles';
import { linkNearTextStyle } from './CollapsibleDescription.constants';

export function CollapsibleDescription(props: {
    title: string;
    description: string;
    defaultStyle: CSSProperties;
    cardType: CardType;
}): React.ReactElement {
    /** Description reference */
    const descRef = React.useRef(null);
    const [showSeeMore, setShowSeeMore] = React.useState(false);
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [prevScrollHeight, setPrevScrollHeight] = React.useState(-1);

    const truncatedStyle = truncatedElementCardDescriptionStyles(
        props.cardType
    );
    const maxCollapsedHeight = collapsedDescriptionHeight[props.cardType];

    React.useEffect(() => {
        if (descRef.current?.scrollHeight !== prevScrollHeight) {
            setPrevScrollHeight(descRef.current.scrollHeight);
            if (descRef.current.scrollHeight > maxCollapsedHeight) {
                setShowSeeMore(true);
                setIsCollapsed(true);
            } else {
                // isCollapsed is ignored because the description isn't tall enough.
                setShowSeeMore(false);
            }
        }
    }, [prevScrollHeight, maxCollapsedHeight, props.description]);

    return (
        <div>
            <div
                ref={descRef}
                style={isCollapsed ? truncatedStyle : props.defaultStyle}
            >
                <div
                    // For further reading about dangerouslySetInnerHtml and to confirm it is ok https://reactjs.org/docs/dom-elements.html
                    // Any type of sanitization would be handled by LAE/Coherence RTE or the service side so we haven't included it here.
                    dangerouslySetInnerHTML={{
                        __html: props.description,
                    }}
                ></div>
            </div>
            {showSeeMore && isCollapsed && (
                <Link
                    style={{ marginTop: '0px' }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    aria-label={`Read more about ${props.title}`}
                    styles={linkNearTextStyle}
                >
                    {`Read more`}
                </Link>
            )}
            {showSeeMore && !isCollapsed && (
                <Link
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    aria-label={`Read less about ${props.title}`}
                    styles={linkNearTextStyle}
                >{`Read less`}</Link>
            )}
        </div>
    );
}
