import { MessageBarType } from '@fluentui/react';
import { ServiceCallType } from '../Shared/constants';
import {
    IReceiveCatalogItems,
    IReceiveLearningPath,
    IReceiveTargeting,
    IReceiveStatuses,
    LearningPathActionType,
    IFailLearningPathRequest,
    ISetSelectedGroupId,
    IRequestLearningPath,
    ISetQueryStringParameters,
    IFailedStatusesRequest,
    IResetGeneralMessage,
    IUpdateLoading,
    ISetTreeNavigationExpanded,
} from './LearningPath.actions';
import {
    ExpiryDate,
    statusErrorMessage,
    statusErrorMessageAdminView,
} from './LearningPath.constants';
import { LearningPathType, StatusType } from './LearningPath.types';
import {
    updateCatalogItemProperties,
    updateStatuses,
    updateAllStatusesToType,
    showErrorPageBasedOnServiceCall,
    learningPathChangedMessage,
} from './LearningPath.utils';
import { ILearningPath, ILearningPathCatalogItem } from './Models/LearningPath';
import { ITargeting } from './Models/Targeting';

export interface ILearningPathErrorInfo {
    status: number;
    serviceCall: ServiceCallType;
    showErrorPage: boolean;
}

export interface ILearningPathGeneralMessage {
    messageBarType: MessageBarType;
    message: string;
}

export interface ILearningPathState {
    learningPath: ILearningPath;
    errorInfo: ILearningPathErrorInfo;
    targeting: ITargeting;
    selectedGroupId: string;
    isLoading: boolean;
    queryStringParameters: string;
    treeExpanded: boolean;
    generalMessage: ILearningPathGeneralMessage;
    isAdmin: boolean;
    expiringCourses: ILearningPathCatalogItem[];
    expiredCourses: ILearningPathCatalogItem[];
    apiCount: number;
}

export const getLearningPathInitialState = (): ILearningPathState => {
    const initialLearningPath: ILearningPath = {
        id: null,
        name: '',
        description: '',
        length: {
            hours: 0,
            minutes: 0,
        },
        isActive: true,
        isPublished: false,
        imagePath: null,
        parentGroup: {
            parent: null,
            type: LearningPathType.LearningPathGroup,
            id: '-1',
            name: '',
            description: '',
            groupData: {
                requiredItemCount: 1,
                mandatory: true,
                sequenceEnforcement: false,
            },
            order: 0,
            level: 0,
            status: StatusType.NotStarted,
            children: [],
            expiryDate: ExpiryDate.Unknown,
            ignoreCompletion: false,
        },
        status: StatusType.NotStarted,
        modifiedStructureTimeStamp: undefined,
    };
    const initialState: ILearningPathState = {
        learningPath: initialLearningPath,
        targeting: {
            assignedBy: null,
            completeByDate: null,
            isActive: false,
        },
        selectedGroupId: initialLearningPath.parentGroup.id,
        isLoading: false,
        queryStringParameters: null,
        errorInfo: null,
        generalMessage: {
            message: '',
            messageBarType: MessageBarType.success,
        },
        treeExpanded: true,
        isAdmin: false,
        expiredCourses: [],
        expiringCourses: [],
        apiCount: 0,
    };
    return initialState;
};

export function learningPathReducer(
    prev: ILearningPathState = getLearningPathInitialState(),
    action:
        | IReceiveLearningPath
        | IReceiveCatalogItems
        | IReceiveStatuses
        | IFailLearningPathRequest
        | IReceiveTargeting
        | IReceiveStatuses
        | ISetSelectedGroupId
        | IRequestLearningPath
        | ISetQueryStringParameters
        | IFailedStatusesRequest
        | IResetGeneralMessage
        | IUpdateLoading
        | ISetTreeNavigationExpanded
): ILearningPathState {
    switch (action.type) {
        case LearningPathActionType.ReceiveLearningPath:
            return {
                ...prev,
                learningPath: action.learningPath,
                selectedGroupId:
                    action.groupId ?? action.learningPath.parentGroup?.id,
                errorInfo: null,
                apiCount: prev.apiCount + 1,
                // Non-empty parent group needs to load catalog details.
                // Empty parent group is done loading at this point.
                isLoading: action.learningPath.parentGroup.name.length > 0,
            };
        case LearningPathActionType.ReceiveCatalogItems:
            const updatedLearningPathWithCatalogInfo =
                updateCatalogItemProperties(action.items, prev.learningPath);
            return {
                ...prev,
                apiCount: prev.apiCount + 1,
                learningPath: updatedLearningPathWithCatalogInfo,
            };
        case LearningPathActionType.ReceiveTargeting:
            return {
                ...prev,
                apiCount: prev.apiCount + 1,
                targeting: action.targeting,
                isAdmin: action.isAdmin,
            };
        case LearningPathActionType.ReceiveStatuses:
            const updatedLearningPath = updateStatuses(
                action.mappedStatuses,
                prev.learningPath
            );
            const message = learningPathChangedMessage(
                updatedLearningPath.parentGroup.completionDate,
                prev.learningPath.modifiedTimeStamp,
                prev.learningPath.modifiedStructureTimeStamp
            );
            return {
                ...prev,
                apiCount: prev.apiCount + 1,
                learningPath: updatedLearningPath,
                isAdmin: action.isAdmin,
                generalMessage: {
                    message: message,
                    messageBarType: message
                        ? MessageBarType.warning
                        : MessageBarType.success,
                },
            };
        case LearningPathActionType.FailLearningPathRequest:
            return {
                ...prev,
                errorInfo: prev.errorInfo?.showErrorPage
                    ? prev.errorInfo
                    : {
                          status: action.message.status,
                          serviceCall: action.serviceCall,
                          showErrorPage: showErrorPageBasedOnServiceCall(
                              action.serviceCall,
                              action.isAdmin,
                              action.message.status
                          ),
                      },
                isLoading:
                    action.serviceCall === ServiceCallType.LearningPath ||
                    action.serviceCall === ServiceCallType.CatalogItems
                        ? false
                        : prev.isLoading,
            };
        case LearningPathActionType.SetSelectedGroupId:
            return {
                ...prev,
                selectedGroupId: action.selectedGroupId,
            };
        case LearningPathActionType.RequestLearningPath:
            return {
                ...prev,
                apiCount: 0,
                isLoading: true,
            };
        case LearningPathActionType.SetQueryStringParameters:
            return {
                ...prev,
                queryStringParameters: action.queryStringParameters,
            };
        case LearningPathActionType.FailedStatusesRequest:
            const learningPathUnknownStatuses = updateAllStatusesToType(
                prev.learningPath,
                StatusType.Unknown
            );
            return {
                ...prev,
                learningPath: learningPathUnknownStatuses,
                generalMessage: {
                    message: action.isAdmin
                        ? action.errorMessage.length
                            ? action.errorMessage
                            : statusErrorMessageAdminView
                        : statusErrorMessage,
                    messageBarType: MessageBarType.warning,
                },
            };
        case LearningPathActionType.ResetGeneralMessage:
            return {
                ...prev,
                generalMessage: {
                    message: '',
                    messageBarType: MessageBarType.success,
                },
            };

        case LearningPathActionType.UpdateLoading:
            return {
                ...prev,
                isLoading: action.isLoading,
            };
        case LearningPathActionType.SetTreeNavigationExpanded:
            return {
                ...prev,
                treeExpanded: action.isExpanded,
            };
        default:
            return prev;
    }
}
