import {
    FontSizes,
    FontWeights,
    IStackTokens,
    mergeStyleSets,
} from '@fluentui/react';
import { CSSProperties } from 'styled-components';

export enum ScreenWidthSelector {
    /** Screen width in the range [1920, infinity) pixels */
    XxxLarge = '@media (min-width: 1920px)',
    /** Screen width in the range [1366 , 1920) pixels*/
    XxLarge = '@media (min-width: 1366px) and (max-width: 1919px)',
    /** Screen width in the range [1024 , 1366) pixels*/
    XLarge = '@media (min-width: 1024px) and (max-width: 1365px)',
    /** Screen width in the range [640 , 1024) pixels*/
    Large = '@media (min-width: 640px) and (max-width: 1023px)',
    /** Screen width in the range [480 , 640) pixels*/
    Medium = '@media (min-width: 480px) and (max-width: 639px)',
    /** Screen width in the range [0 , 480) pixels*/
    Small = '@media (max-width: 479px)',
}
/**
 * Tracks Coherence spacing values
 * @link https://coherence-portal.azurewebsites.net/Styles/Spacing#Overview
 */
export enum Spacing {
    SIZE_4 = 4,
    SIZE_8 = 8,
    SIZE_12 = 12,
    SIZE_16 = 16,
    SIZE_24 = 24,
    SIZE_32 = 32,
    SIZE_48 = 48,
    SIZE_64 = 64,
    SIZE_96 = 96,
    SIZE_128 = 128,
    SIZE_192 = 192,
    SIZE_256 = 256,
}

/**
 * Constants used for IStackTokens children gap
 * @example childrenGap[4]
 * Intellisense should also display the values when you type "childrenGap."
 * and automatically convert it to the bracket notation when you select a value.
 */
export const childrenGap: Record<Spacing, IStackTokens> = {
    4: { childrenGap: Spacing.SIZE_4 },
    8: { childrenGap: Spacing.SIZE_8 },
    12: { childrenGap: Spacing.SIZE_12 },
    16: { childrenGap: Spacing.SIZE_16 },
    24: { childrenGap: Spacing.SIZE_24 },
    32: { childrenGap: Spacing.SIZE_32 },
    48: { childrenGap: Spacing.SIZE_48 },
    64: { childrenGap: Spacing.SIZE_64 },
    96: { childrenGap: Spacing.SIZE_96 },
    128: { childrenGap: Spacing.SIZE_128 },
    192: { childrenGap: Spacing.SIZE_192 },
    256: { childrenGap: Spacing.SIZE_256 },
};

export const fontClassNames = mergeStyleSets({
    /** Segoe UI 12 regular */
    caption: { fontSize: FontSizes.size12 },
    /** Segoe UI 14 regular */
    body: { fontSize: FontSizes.size14 },
    /** Segoe UI 14 semibold */
    base: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
    },
    /** Segoe UI 18 semibold */
    subheader: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.semibold,
    },
    /** Segoe UI 26 bold */
    header: {
        fontSize: 26,
        fontWeight: FontWeights.bold,
    },
    /** Segoe UI 42 bold */
    hero: {
        fontSize: FontSizes.size42,
        fontWeight: FontWeights.bold,
    },
    headerCardHeaderStyles: {
        fontSize: '26px',
        fontWeight: FontWeights.semibold,
    },
});

export const descriptionLineHeight = 20;
export const childCardTitleLineHeight = 24;

export enum ServiceCallType {
    LearningPath = 'LearningPath',
    CatalogItems = 'CatalogItems',
    Targeting = 'Targeting',
    Statuses = 'Statuses',
}

export const ErrorViewTitle: Record<number, string> = {
    400: 'Your browser sent a request that the server could not understand.',
    401: 'Access denied: Unauthorized.',
    403: 'Access denied: Forbidden.',
    500: 'Something went wrong. Please try again later.',
};

export const accessDeniedMessage =
    'Please contact AskLearning@microsoft.com for access.';
export enum CardType {
    Header = 'Header',
    Child = 'Child',
}

export const collapsedDescriptionHeight: Record<CardType, number> = {
    [CardType.Header]: 60,
    [CardType.Child]: 40,
};

export const styleForMain: CSSProperties = {
    marginLeft: '0px',
    width: '100%',
    flex: '1 1 auto',
};

export const heightClassName = mergeStyleSets({
    fullHeight: {
        height: '100%',
    },
});
