import { Stack } from '@fluentui/react';
import * as React from 'react';
import { LearningPathType } from '../LearningPath.types';
import { rootCardstyles } from './LearningPathElementCard.constants';
import { ILearningPathElementProps } from './LearningPathElementCard.types';
import { LearningPathCourseCard } from './LearningPathCourseCard';
import { LearningPathSectionCard } from './LearningPathSectionCard';

export function LearningPathElementCard(
    props: ILearningPathElementProps
): React.ReactElement {
    const { element, isEnabled } = props;
    return (
        <Stack horizontalAlign="center" styles={rootCardstyles}>
            {element.type === LearningPathType.LearningPathItem ? (
                <LearningPathCourseCard item={element} isEnabled={isEnabled} />
            ) : (
                <LearningPathSectionCard
                    group={element}
                    isEnabled={isEnabled}
                />
            )}
        </Stack>
    );
}
