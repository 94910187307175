import {
    ILearningPath,
    IServiceLearningPath,
    IServiceStatusInfo,
    ILearningPathCatalogItem,
    ILearningPathGroup,
    IServiceLearningPathElement,
    IStatusInfo,
    ILearningPathCompletionMetadata,
    ILearningPathElement,
} from './Models/LearningPath';
import { IServiceTargeting, ITargeting } from './Models/Targeting';
import {
    CatalogItemProperties,
    CatalogLengthUnit,
    ServiceCatalogLengthUnit,
    ServiceLearningPathLengthUnit,
    LearningPathType,
    StatusType,
    SelectionType,
} from './LearningPath.types';
import { ICatalogInput, IServiceCatalogItem } from './Models/CatalogItem';
import { ExpiryDate, learningPathHashHeader } from './LearningPath.constants';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

export function updateParentReferences(
    node: ILearningPathGroup
): ILearningPathGroup {
    const queue: Array<ILearningPathElement> = [];
    const deepClonedNode = cloneDeep(node);
    queue.push(deepClonedNode);
    while (queue.length) {
        const currNode = queue.pop();
        currNode.children.forEach((child) => {
            child.parent = currNode as ILearningPathGroup;
            queue.push(child);
        });
    }
    return deepClonedNode;
}

/** Corresponds to  mockLearningPath */
export const mockServiceLearningPath: IServiceLearningPath = {
    id: 'Mock-service-id',
    title: 'Mock service title',
    description: 'Mock service description',
    length: {
        value: 2,
        unit: ServiceLearningPathLengthUnit.Minutes,
    },
    isPublished: false,
    parentGroup: null,
    isActive: true,
    imagePath: 'mock imgPath',
};

/** Corresponds to  mockServiceTargeting */
export const mockTargeting: ITargeting = {
    assignedBy: 'v-testUser1',
    completeByDate: '2021-01-31T18:38:45.3933817Z',
    isActive: true,
};

/** Corresponds to  mockTargeting */
export const mockServiceTargeting: IServiceTargeting = {
    AssignedBy: 'v-testUser1',
    CompleteByDate: '2021-01-31T18:38:45.3933817Z',
    IsActive: true,
};

export const mockServiceCatalogItem: IServiceCatalogItem = mockServiceItem({
    ExternalId: '1001',
    Url: 'test url',
    Length: {
        Value: 3,
        Unit: ServiceCatalogLengthUnit.Page,
    },
    Description: 'test description',
});

/** Corresponds to  mockCatalogItems */
export const mockServiceCatalogItems: IServiceCatalogItem[] = [
    mockServiceItem({
        ExternalId: 'A_3_pages',
        Url: 'A_url',
        Length: {
            Value: 3,
            Unit: ServiceCatalogLengthUnit.Page,
        },
        Description: 'A_description',
        Title: 'A_name',
    }),
    mockServiceItem({
        ExternalId: 'B_2400_seconds',
        Url: 'B_url',
        Length: {
            Value: 2400,
            Unit: ServiceCatalogLengthUnit.Seconds,
        },
        Description: 'B_description',
        Title: 'B_name',
    }),
    mockServiceItem({
        ExternalId: 'C_2000_words',
        Url: 'C_url',
        Length: {
            Value: 2000,
            Unit: ServiceCatalogLengthUnit.WordCount,
        },
        Description: 'C_description',
        Title: 'C_name',
    }),
];

/** Corresponds to  mockServiceCatalogItems */
export const mockCatalogItems: ILearningPathCatalogItem[] = [
    mockItem({
        id: '1001',
        name: 'test item1',
        description: 'test description1',
        sourceSystem: 'MSLearn',
        order: 1,
        level: 1,
        status: StatusType.InProgress,
        url: null,
    }),
    mockItem({
        id: '1002',
        name: 'test item2',
        description: 'test description2',
        sourceSystem: 'SuccessFactor',
        order: 1,
        level: 1,
        status: StatusType.InProgress,
        url: null,
    }),
    mockItem({
        id: '1003',
        name: 'test item3',
        description: 'test description3',
        sourceSystem: 'MSLearn',
        order: 1,
        level: 1,
        status: StatusType.Completed,
        url: null,
    }),
];
/**
 * Corresponds to mockMappedClientStatuses
 */
export const mockServiceStatuses: IServiceStatusInfo[] = [
    mockServiceStatus({
        Id: '-1',
    }),
    mockServiceStatus({
        Id: 'A_Not_Started',
        Status: StatusType.NotStarted,
        CompletionDate: '2022-07-05T20:03:56+00:00',
    }),
    mockServiceStatus({
        Id: 'G1_In_Progress',
    }),
    mockServiceStatus({
        Id: 'Sub_G1_In_Progress',
    }),
    mockServiceStatus({
        Id: 'A_Not_Started',
        Status: StatusType.NotStarted,
        CompletionDate: '2022-07-05T20:03:56+00:00',
        IsIgnored: true,
    }),
    mockServiceStatus({
        Id: 'B_In_Progress',
    }),
    mockServiceStatus({
        Id: 'G2_In_Progress',
    }),
    mockServiceStatus({
        Id: 'A_Not_Started',
        Status: StatusType.NotStarted,
        CompletionDate: '2022-07-05T20:03:56+00:00',
        IsIgnored: true,
    }),
    mockServiceStatus({
        Id: 'C_Completed',
        Status: 'Completed',
    }),
    mockServiceStatus({
        Id: 'Alpha_alternative',
        Status: 'CompletedByAlternative',
    }),
    mockServiceStatus({
        Id: 'Beta_attended',
        Status: 'Attended',
    }),
    mockServiceStatus({
        Id: 'Eta_Waived',
        Status: 'Waived',
    }),
    mockServiceStatus({
        Id: 'gAmma_passed',
        Status: 'Passed',
    }),
    mockServiceStatus({
        Id: 'zed_',
        Status: 'Interested',
    }),
    mockServiceStatus({
        Id: '__init__',
        Status: 'Cancelled',
    }),
    mockServiceStatus({
        Id: 'hello-world',
        Status: 'Registered',
    }),
    mockServiceStatus({
        Id: 'Ferris_Bugler',
        Status: 'Waitlisted',
    }),
    mockServiceStatus({
        Id: 'OMEGA_THETA',
        Status: 'Failed',
    }),
    mockServiceStatus({
        Id: 'G3_complete',
        Status: 'Completed',
        IsIgnored: true,
        CompletionDate: '2022-07-05T20:03:56+00:00',
    }),
    mockServiceStatus({
        Id: 'FIVE_GUYS',
        Status: 'HelloWorld',
    }),
];

/**
 * Corresponds to mockServiceStatuses after being transformed into a Map<id, IStatusInfo>.
 */
export const mockMappedClientStatuses: IStatusInfo[] = [
    mockStatusInfo({
        id: '-1',
        status: StatusType.InProgress,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'a_not_started',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
        completionDate: '2022-07-05T20:03:56+00:00',
    }),
    mockStatusInfo({
        id: 'g1_in_progress',
        status: StatusType.InProgress,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'sub_g1_in_progress',
        status: StatusType.InProgress,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'a_not_started',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
        completionDate: '2022-07-05T20:03:56+00:00',
        ignoreCompletion: true,
    }),
    mockStatusInfo({
        id: 'b_in_progress',
        status: StatusType.InProgress,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'g2_in_progress',
        status: StatusType.InProgress,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'a_not_started',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
        completionDate: '2022-07-05T20:03:56+00:00',
        ignoreCompletion: true,
    }),
    mockStatusInfo({
        id: 'c_completed',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'alpha_alternative',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'beta_attended',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'eta_waived',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'gamma_passed',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'zed_',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: '__init__',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'hello-world',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'ferris_bugler',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'omega_theta',
        status: StatusType.NotStarted,
        expiryDate: ExpiryDate.None,
    }),
    mockStatusInfo({
        id: 'g3_complete',
        status: StatusType.Completed,
        expiryDate: ExpiryDate.None,
        ignoreCompletion: true,
        completionDate: '2022-07-05T20:03:56+00:00',
    }),
    mockStatusInfo({
        id: 'five_guys',
        status: StatusType.Unknown,
        expiryDate: ExpiryDate.None,
    }),
];

export const mockParentGroup: IServiceLearningPathElement = {
    id: '-1',
    name: 'test',
    description: '',
    order: 0,
    sourceSystem: null,
    score: 1,
    group: {
        isMandatory: true,
        criteria: {
            selectionType: SelectionType.Random,
            totalScore: 1,
            requiredScore: 1,
        },
    },
    items: [
        {
            id: 'NewPlayerPdfId',
            name: 'Test title upsdfed',
            description: 'This is an awesome test course.',
            order: 0,
            sourceSystem: 'SuccessFactors',
            score: 1,
            group: null,
            items: null,
        },
    ],
};

export const mockServiceLearningPathAndParentGroup: IServiceLearningPath = {
    id: 'Mock-service-id',
    title: 'Mock service title',
    description: 'Mock service description',
    length: {
        value: 2,
        unit: ServiceLearningPathLengthUnit.Minutes,
    },
    isPublished: false,
    parentGroup: {
        id: '-1',
        name: 'test',
        description: '',
        order: 0,
        sourceSystem: null,
        score: 1,
        group: {
            isMandatory: true,
            criteria: {
                selectionType: SelectionType.Sequential,
                totalScore: 1,
                requiredScore: 1,
            },
        },
        items: [
            {
                id: 'NewPlayerPdfId',
                name: 'Test title upsdfed',
                description: 'This is an awesome test course.',
                order: 0,
                sourceSystem: 'SuccessFactors',
                score: 1,
                group: null,
                items: null,
            },
        ],
    },
    isActive: true,
    imagePath: 'mock imgPath',
};

export const mockServiceLearningPathHeaders = {
    [learningPathHashHeader]: 'MockHashValue',
};

export const parentGroupForTest: ILearningPathGroup = mockGroup(
    {
        id: '-1',
        name: 'parentGroup',
        description: '',
        order: 0,
        level: 0,
    },
    [
        mockGroup(
            {
                id: 'testIdLevel1',
                name: 'Test group level 1',
                description: '',
                order: 0,
                level: 0,
            },
            [
                mockGroup({
                    id: 'testIdLevel2',
                    name: 'Test group level 2',
                    description: '',
                    order: 0,
                    level: 0,
                }),
            ]
        ),
    ]
);

/**
 * Corresponds to mock Map<string, CatalogItemProperties> that mapToClientCatalogItems returned.
 */
export const mockMappedClientCatalogItems: Map<string, CatalogItemProperties> =
    new Map([
        [
            'a_3_pages',
            {
                url: 'A_url',
                length: {
                    value: 3,
                    unit: CatalogLengthUnit.Page,
                },
                description: 'A_description',
                name: 'A_name',
            },
        ],
        [
            'b_2400_seconds',
            {
                url: 'B_url',
                length: {
                    value: 2400,
                    unit: CatalogLengthUnit.Seconds,
                },
                description: 'B_description',
                name: 'B_name',
            },
        ],
        [
            'c_2000_words',
            {
                url: 'C_url',
                length: {
                    value: 2000,
                    unit: CatalogLengthUnit.WordCount,
                },
                description: 'C_description',
                name: 'C_name',
            },
        ],
    ]);

export const mockCatalogIdentifyInfo: ICatalogInput[] = [
    {
        SourceSystem: 'SourceSystem1',
        ExternalId: 'textExternalId1',
    },
    {
        SourceSystem: 'SourceSystem2',
        ExternalId: 'textExternalId2',
    },
    {
        SourceSystem: 'SourceSystem3',
        ExternalId: 'textExternalId3',
    },
];
export function mockLearningPath(
    dummy: Partial<ILearningPath> = {}
): ILearningPath {
    const mockLearningPath: ILearningPath = {
        id: '-1',
        name: 'mock-learning-path',
        description: 'mock description',
        length: {
            hours: 0,
            minutes: 0,
        },
        isPublished: true,
        parentGroup: null,
        isActive: true,
        imagePath: 'mock-thumbnail-url',
        status: StatusType.NotStarted,
    };
    return {
        ...mockLearningPath,
        ...dummy,
    };
}

export function mockItem(
    dummy: Partial<ILearningPathCatalogItem> = {}
): ILearningPathCatalogItem {
    const mockCatalogItem: ILearningPathCatalogItem = {
        type: LearningPathType.LearningPathItem,
        id: dummy.id ? dummy.id : uuidv4(),
        name: 'test item name',
        description: 'test item desc',
        sourceSystem: 'test source system',
        status: StatusType.NotStarted,
        length: null,
        url: 'mock-url',
        expiryDate: ExpiryDate.None,
        parent: null,
        completionDate: '',
        ignoreCompletion: false,
        children: [],
    };
    return {
        ...mockCatalogItem,
        ...dummy,
    };
}

export function mockGroup(
    dummy: Partial<ILearningPathGroup> = {},
    children: ILearningPathElement[] = []
): ILearningPathGroup {
    const mockGroup: ILearningPathGroup = {
        type: LearningPathType.LearningPathGroup,
        id: dummy.id ? dummy.id : uuidv4(),
        name: 'test group name',
        description: 'test group desc',
        groupData: {
            sequenceEnforcement: false,
            requiredItemCount: 0,
            mandatory: false,
        },
        status: StatusType.Unknown,
        expiryDate: ExpiryDate.None,
        parent: null,
        children,
        ignoreCompletion: false,
        completionDate: '',
    };
    return updateParentReferences({ ...mockGroup, ...dummy });
}

export function mockServiceStatus(
    dummy: Partial<IServiceStatusInfo>
): IServiceStatusInfo {
    const mockStatus: IServiceStatusInfo = {
        Id: 'test',
        Status: 'InProgress',
        ExpiryDate: null,
        IsIgnored: undefined,
        CompletionDate: undefined,
    };
    return {
        ...mockStatus,
        ...dummy,
    };
}

export function mockStatusInfo(dummy: Partial<IStatusInfo>): IStatusInfo {
    const mockStatus = {
        id: 'testItem',
        status: StatusType.NotStarted,
        expiryDate: '',
        ignoreCompletion: false,
        completionDate: '',
    };
    return {
        ...mockStatus,
        ...dummy,
    };
}

export function mockServiceItem(
    dummy: Partial<IServiceCatalogItem> = {}
): IServiceCatalogItem {
    const mockItem = {
        ExternalId: 'test12345',
        Url: 'https://www.microsoft.com',
        Length: {
            Value: 1,
            Unit: ServiceCatalogLengthUnit.Seconds,
        },
        Description: 'This is a test.',
        Title: 'test title',
    };
    return {
        ...mockItem,
        ...dummy,
    };
}
/**
 *
 * By default returns completion metadata object fields instantiated to zero.
 * Any desired non-zero values should be passed in the dummy object.
 *
 * @param dummy Partial completionMetadata object
 * @returns completionMetadata object for testing/debugging
 */
export function mockCompletionMetadata(
    dummy: Partial<ILearningPathCompletionMetadata> = {}
): ILearningPathCompletionMetadata {
    return {
        requiredCount: 0,
        totalCount: 0,
        requiredSectionsCount: 0,
        ...dummy,
    };
}
