import { Icon, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { CollapsibleDescription } from '../../Shared/Components/CollapsibleDescription/CollapsibleDescription';
import { StatusBar } from '../../Shared/Components/StatusBar/StatusBar';
import {
    CardType,
    fontClassNames,
    childrenGap,
    childCardTitleLineHeight,
    Spacing,
} from '../../Shared/constants';
import {
    calculateLengthString,
    getExpirationMessage,
} from '../../Shared/utils';
import { descriptionStyles } from '../LearningPath.styles';
import { ILearningPathGroup } from '../Models/LearningPath';
import {
    cardStackStyles,
    cardIconStyles,
    learningPathCourseCardLabels,
    courseItemsStack,
    titleDescriptionStackStyles,
    childCardstyles,
    expiredMessageText,
    expiringMessageText,
    getCoherenceCardStyles,
    regularCardStackProps,
} from './LearningPathElementCard.constants';
import { useHistory } from 'react-router-dom';
import { useLearningPathState } from '../useLearningPathState';
import { getUrl } from '../LearningPath.utils';
import { LabeledValue } from '../../Shared/Components/LabeledValue/LabeledValue';
import { Card } from '@coherence-design-system/controls';
import { StatusType } from '../LearningPath.types';
import { DescriptionType } from '../LearningPath.constants';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { TruncatedTitleTextOverFlow } from '../../Shared/Components/TruncatedTitleTextOverFlow/TruncatedTitleTextOverFlow';

export function LearningPathSectionCard(props: {
    group: ILearningPathGroup;
    isEnabled: boolean;
}): React.ReactElement {
    const { group, isEnabled } = props;
    const history = useHistory();
    const { learningPath, queryStringParameters } = useLearningPathState();
    const { telemetryClient } = React.useContext(ComponentContext);
    const onClick = () => {
        history.push(getUrl(learningPath.id, group.id, queryStringParameters));
    };
    return (
        <Stack styles={childCardstyles} tokens={childrenGap[8]}>
            <Card styles={getCoherenceCardStyles(group.status)}>
                <Stack
                    tokens={cardStackStyles}
                    verticalAlign="center"
                    styles={courseItemsStack}
                >
                    <Stack
                        verticalAlign="center"
                        styles={titleDescriptionStackStyles}
                    >
                        <Stack {...regularCardStackProps}>
                            <Icon iconName={'Stack'} styles={cardIconStyles} />
                            <span
                                id={group.id}
                                className={fontClassNames.subheader}
                            >
                                <TruncatedTitleTextOverFlow
                                    name={group.name}
                                    maxLines={2}
                                    lineHeight={childCardTitleLineHeight}
                                />
                            </span>
                        </Stack>
                        {group.description.length > 0 && (
                            <Stack
                                styles={{
                                    root: {
                                        marginLeft: Spacing.SIZE_32,
                                        ul: { 'list-style': 'disc' },
                                    },
                                }}
                            >
                                <CollapsibleDescription
                                    title={group.name}
                                    description={group.description}
                                    defaultStyle={descriptionStyles}
                                    cardType={CardType.Child}
                                />
                            </Stack>
                        )}
                    </Stack>

                    <LabeledValue
                        label={learningPathCourseCardLabels.sectionLength}
                        value={calculateLengthString(group, telemetryClient)}
                    />

                    <StatusBar
                        status={group.status}
                        expiryDate={group.expiryDate}
                        completionDate={group.completionDate}
                        renderDate={group.ignoreCompletion}
                    />
                    <PrimaryButton
                        disabled={!isEnabled}
                        onClick={onClick}
                        aria-label={`${group.name} open`}
                    >
                        {'Open'}
                    </PrimaryButton>
                </Stack>
            </Card>
            {(group.status === StatusType.ExpiringSoon ||
                group.status === StatusType.Expired) && (
                <span
                    style={
                        group.status === StatusType.ExpiringSoon
                            ? expiringMessageText
                            : expiredMessageText
                    }
                >
                    {getExpirationMessage(DescriptionType.Group, group.status)}
                </span>
            )}
        </Stack>
    );
}
