import { Stack } from '@fluentui/react';
import * as React from 'react';
import { fontClassNames } from '../../Shared/constants';
import { useLearningPathState } from '../useLearningPathState';
import {
    coherenceTreeStyles,
    treeItemDelimiter,
    treeStackStyles,
} from './LearningPathTree.constants';
import {
    makeTreeChildrenFromPath,
    calculateTreeHeight,
} from './LearningPathTree.utils';
import { History } from 'history';
import { getUrl } from '../LearningPath.utils';
import { CoherenceTreeItem } from '@coherence-design-system/controls';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { TreeView } from '@coherence-design-system/tree-view';

export function LearningPathTreeView(props: {
    history: History<unknown>;
    reportHeight: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement {
    const {
        learningPath,
        selectedGroupId,
        queryStringParameters,
        treeExpanded,
    } = useLearningPathState();
    const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
    const { history, reportHeight } = props;
    function onTreeItemClick(
        _: React.MouseEvent<Element, MouseEvent>,
        item: CoherenceTreeItem
    ): void {
        const groupId =
            item.id.split(treeItemDelimiter).length > 1
                ? item.id.split(treeItemDelimiter)[1]
                : item.id;
        history.push(getUrl(learningPath.id, groupId, queryStringParameters));
    }

    React.useEffect(() => {
        reportHeight(
            calculateTreeHeight(learningPath.parentGroup, expandedItems)
        );
    }, [expandedItems, learningPath.parentGroup, reportHeight]);

    return (
        <Stack
            className={
                treeExpanded
                    ? treeStackStyles.treeExpanded
                    : treeStackStyles.treeNotExpanded
            }
            verticalFill
            styles={{
                root: { backgroundColor: `${CoherenceTheme.palette.white}` },
            }}
        >
            {treeExpanded && (
                <Stack className={treeStackStyles.treeComponent}>
                    <div className={treeStackStyles.titleButton}>
                        <button
                            className={`${fontClassNames.subheader} focus-visible`}
                            onClick={() => {
                                history.push(
                                    getUrl(
                                        learningPath.id,
                                        null,
                                        queryStringParameters
                                    )
                                );
                            }}
                        >
                            {learningPath.parentGroup.name}
                        </button>
                    </div>
                    <TreeView
                        styles={coherenceTreeStyles}
                        data={makeTreeChildrenFromPath(
                            learningPath.parentGroup.children,
                            selectedGroupId,
                            onTreeItemClick,
                            expandedItems
                        )}
                        onItemToggled={(
                            item: CoherenceTreeItem,
                            isExpanded: boolean
                        ): void => {
                            if (isExpanded) {
                                setExpandedItems([...expandedItems, item.id]);
                            } else {
                                setExpandedItems(
                                    expandedItems.filter(
                                        (treeId: string) => treeId !== item.id
                                    )
                                );
                            }
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
}
