import * as React from 'react';
import {
    learningPathHeaderCardProps,
    descriptionStyles,
    lpLabelStyle,
    groupHeaderCardContentProps,
    groupHeaderTitleStyles,
    completionStatusStackStyles,
    statusNoteStyles,
} from '../LearningPath.styles';
import { Icon, Stack } from '@fluentui/react';
import { CardType, childrenGap } from '../../Shared/constants';
import { StatusBar } from '../../Shared/Components/StatusBar/StatusBar';
import { CompletionStatus } from '../../Shared/Components/CompletionStatus/CompletionStatus';
import { styleForGroupDescription } from './GroupHeaderCardConstants';
import {
    findLearningPathGroup,
    getChildrenElementType,
    getCompletedItemsCount,
    getCompletionMessage,
} from '../LearningPath.utils';
import { useLearningPathState } from '../useLearningPathState';
import { CollapsibleDescription } from '../../Shared/Components/CollapsibleDescription/CollapsibleDescription';
import {
    groupHeaderDescriptionLabel,
    statusNoteText,
} from '../LearningPath.constants';
import { CustomProgressIndicator } from '../../Shared/Components/CustomProgressIndicator/CustomProgressIndicator';
export function GroupHeaderCard(): React.ReactElement {
    const { learningPath, selectedGroupId } = useLearningPathState();
    const selectedGroup = findLearningPathGroup(
        learningPath.parentGroup,
        selectedGroupId
    );
    const completedItems = getCompletedItemsCount(selectedGroup);
    return (
        <Stack {...learningPathHeaderCardProps}>
            <Stack {...groupHeaderCardContentProps}>
                <Stack style={{ justifyContent: 'space-between' }}>
                    <Stack
                        tokens={childrenGap[8]}
                        styles={{ root: { marginBottom: '20px' } }}
                    >
                        <Stack
                            horizontal
                            tokens={childrenGap[8]}
                            verticalAlign="center"
                            styles={{ ...groupHeaderTitleStyles }}
                        >
                            <Icon iconName={'Stack'} />
                            <span>{selectedGroup.name}</span>
                        </Stack>
                        <StatusBar
                            status={selectedGroup.status}
                            expiryDate={selectedGroup.expiryDate}
                        />
                    </Stack>

                    <Stack tokens={childrenGap[12]}>
                        <Stack tokens={childrenGap[8]}>
                            <Stack.Item styles={lpLabelStyle}>
                                {selectedGroup.description.length > 0 &&
                                    groupHeaderDescriptionLabel}
                            </Stack.Item>
                            <Stack.Item styles={statusNoteStyles}>
                                {statusNoteText}
                            </Stack.Item>
                        </Stack>
                        <Stack styles={styleForGroupDescription}>
                            <CollapsibleDescription
                                title={selectedGroup.name}
                                description={selectedGroup.description}
                                defaultStyle={descriptionStyles}
                                cardType={CardType.Header}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                verticalAlign={'center'}
                horizontal
                styles={completionStatusStackStyles}
            >
                <Stack
                    horizontal
                    styles={{ root: { margin: '24px' } }}
                    tokens={childrenGap[24]}
                    verticalAlign="center"
                >
                    <CustomProgressIndicator
                        requiredCount={
                            selectedGroup.groupData.requiredItemCount
                        }
                        completedCount={completedItems}
                    />
                    <CompletionStatus
                        totalCompleted={completedItems}
                        requiredCount={
                            selectedGroup.groupData.requiredItemCount
                        }
                        message={getCompletionMessage(selectedGroup)}
                        childrenType={`${getChildrenElementType(
                            selectedGroup
                        )}s`}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}
