import { CatalogLengthUnit } from '../LearningPath.types';
import { ICatalogLength } from '../Models/CatalogItem';
/**
 *
 * @returns a string with the length to display for any course based on the unit.
 */
export function getCourseLength(length: ICatalogLength): string {
    if (length?.value === 0) return '';
    if (length?.unit === CatalogLengthUnit.Seconds) {
        const hours = Math.floor(length.value / 3600);
        const minutes = Math.floor((length.value % 3600) / 60);
        if (hours > 0 && minutes > 0) {
            return `${hours} hr ${minutes} min`;
        } else if (hours > 0) {
            return `${hours} hr`;
        } else if (minutes > 0) {
            return `${minutes} min`;
        }
    } else if (length?.unit === CatalogLengthUnit.Page) {
        return length.value > 1
            ? `${length.value} pages`
            : `${length.value} page`;
    } else if (length?.unit === CatalogLengthUnit.WordCount) {
        return length.value > 1
            ? `${length.value} words`
            : `${length.value} word`;
    }
}
