import * as React from 'react';
import {
    learningPathStatusStyles,
    statusStackStyles,
} from './StatusBar.styles';
import { StatusType } from '../../../LearningPath/LearningPath.types';
import { Stack } from '@fluentui/react';
import { statusBarText } from './StatusBar.utils';

export function StatusBar(props: {
    status: StatusType;
    expiryDate?: string;
    completionDate?: string;
    renderDate?: boolean;
}): React.ReactElement {
    const { status, expiryDate, completionDate, renderDate } = props;
    return (
        <Stack horizontal {...statusStackStyles}>
            <span style={learningPathStatusStyles(status)}></span>
            <span>
                {statusBarText(status, expiryDate, completionDate, renderDate)}
            </span>
        </Stack>
    );
}
