import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HomePage } from './Pages/HomePage/HomePage';
import { LearningPathMainView } from './Pages/LearningPath/LearningPathMainView';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Route path="/" component={HomePage} exact={true} />
            <Route
                path="/:learningPathId"
                component={LearningPathMainView}
                exact={true}
            />
            <Route
                path="/:learningPathId/:groupId"
                component={LearningPathMainView}
                exact
            />
        </Switch>
    );
}
