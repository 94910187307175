import { CoherenceTheme } from '@coherence-design-system/styles';
import { Spacing } from '../../constants';
import { CSSProperties } from 'react';
import { StatusType } from '../../../LearningPath/LearningPath.types';
import { IStackProps } from '@fluentui/react';

export const stylesForStatusBar: CSSProperties = {
    padding: `${Spacing.SIZE_4} ${Spacing.SIZE_4}`,
    height: 'fit-content',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px',
    width: 'fit-content',
};

export const statusDotStyles: CSSProperties = {
    height: '14px',
    minWidth: '14px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
};

const styleForInProgressStatusBar: CSSProperties = {
    backgroundColor: CoherenceTheme.palette.yellow,
};

const styleForCompletedStatusBar: CSSProperties = {
    backgroundColor: CoherenceTheme.semanticColors.successIcon,
};

const styleForNotStartedStatusBar: CSSProperties = {
    border: `1px solid ${CoherenceTheme.semanticColors.disabledBodyText}`,
};

const styleForExpiringSoonStatusBar: CSSProperties = {
    backgroundColor: CoherenceTheme.palette.red,
};

const styleForExpiredStatusBar: CSSProperties = {
    backgroundColor: CoherenceTheme.palette.redDark,
};

const learningPathStatusDotStyles: Record<StatusType, CSSProperties> = {
    [StatusType.NotStarted]: styleForNotStartedStatusBar,
    [StatusType.InProgress]: styleForInProgressStatusBar,
    [StatusType.Completed]: styleForCompletedStatusBar,
    [StatusType.Unknown]: styleForNotStartedStatusBar,
    [StatusType.ExpiringSoon]: styleForExpiringSoonStatusBar,
    [StatusType.Expired]: styleForExpiredStatusBar,
};

export function learningPathStatusStyles(status: StatusType): CSSProperties {
    return {
        ...statusDotStyles,
        ...learningPathStatusDotStyles[status],
    };
}

export const statusStackStyles: IStackProps = {
    styles: {
        root: {
            alignItems: 'center',
            height: 'fit-content',
            minWidth: '170px',
            justifyContent: 'start',
        },
    },
};
