export enum Capability {
    LearningPathView = 'LearningPathView',
}

export enum SubCapability {
    FetchCatalogItems = 'FetchCatalogItems',
    FetchLearningPath = 'FetchLearningPath',
    FetchTargeting = 'FetchTargeting',
    FetchStatuses = 'FetchStatuses',
}
