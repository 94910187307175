import { StatusType } from '../../../LearningPath/LearningPath.types';
import { isExpiry } from '../../../LearningPath/LearningPath.utils';
import { formatDateString } from '../../date';
import { statusText } from './StatusBar.constants';

export function statusBarText(
    status: StatusType,
    expiryDate?: string,
    completionDate?: string,
    renderDate?: boolean
): string {
    return `${statusText[status]}${
        isExpiry(status) ? ' ' + formatDateString(expiryDate) : ''
    }${
        status === StatusType.Completed && renderDate && !!completionDate
            ? ' on ' + formatDateString(completionDate)
            : ''
    }`;
}
