import * as React from 'react';
import { IButtonProps, MessageBar, MessageBarType } from '@fluentui/react';

export type ICustomMessageBarProps =
    | {
          message: string | JSX.Element;
          messageBarType?: MessageBarType;
          isNonDismissible?: boolean;
          onDismiss?: (ev?: React.MouseEvent<HTMLElement>) => void;
      } & (
          | {
                truncated: true;
                extraContent: string | JSX.Element | JSX.Element[];
            }
          | {
                truncated?: false;
            }
      );

function shouldDisplay(message: string | JSX.Element): boolean {
    return !!message;
}

/**
 * A common message bar. It conditionally renders only when a message is present.
 * If the message is an empty JSX element, it will render.
 * When dismissed, you have to change/reset the message in the props to have it appear again.
 * It is recommended to put the CustomMessageBar within some container,
 * because it will extend to the edge of its container by default.
 */
export function CustomMessageBar(
    props: ICustomMessageBarProps
): React.ReactElement {
    const [display, setDisplay] = React.useState<boolean>(
        shouldDisplay(props.message)
    );

    // Show the error whenever the message is changed.
    React.useEffect(() => {
        setDisplay(shouldDisplay(props.message));
    }, [props]);

    const [showExtraContent, setShowExtraContent] = React.useState(false);
    const expandProps: IButtonProps = {
        iconProps: {
            iconName: showExtraContent
                ? 'DoubleChevronUp'
                : 'DoubleChevronDown',
        },
        onClick: () => {
            setShowExtraContent(!showExtraContent);
        },
        ariaLabel: showExtraContent ? 'View less' : 'View more',
    };

    return (
        display && (
            <MessageBar
                aria-live="assertive"
                dismissButtonAriaLabel="Close"
                messageBarType={props.messageBarType}
                onDismiss={
                    props.isNonDismissible
                        ? null
                        : (): void => {
                              setDisplay(false);
                              if (props.onDismiss) {
                                  props.onDismiss();
                              }
                          }
                }
                role="alert"
                truncated={props.truncated}
                isMultiline={false}
                expandButtonProps={expandProps}
            >
                {props.message}
                {showExtraContent && props.truncated && props.extraContent}
            </MessageBar>
        )
    );
}
