import * as React from 'react';
import { IBreadcrumbItem, Stack } from '@fluentui/react';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import { useDynamicReducer } from '@employee-experience/common/lib/useDynamicReducer';
import {
    requestLearningPath,
    setSelectedGroupId,
    setQueryStringParameters,
} from './LearningPath.actions';
import { learningPathReducer } from './LearningPath.reducers';
import { learningPathSagas } from './LearningPath.sagas';
import { useLearningPathState } from './useLearningPathState';
import { Reducer } from 'redux';
import { LearningPathHeaderCard } from './LearningPathHeaderCard/LearningPathHeaderCard';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ErrorView } from './ErrorView';
import {
    findLearningPathGroup,
    getBreadcrumbItems,
    getCompletionMetadata,
    getErrorTitle,
    hasBothRequiredAndOptional,
    hasIgnoredChildren,
} from './LearningPath.utils';
import { accessDeniedMessage, childrenGap, Spacing } from '../Shared/constants';
import { GroupHeaderCard } from './GroupHeaderCard/GroupHeaderCard';
import { CoherenceLoading } from '@coherence-design-system/controls/lib/CoherenceLoading/CoherenceLoading';
import { GradientBackground } from '../Shared/Components/GradientBackground/GradientBackground';
import {
    learningPathViewStackStylesExpanded,
    learningPathViewStackStylesCollapsed,
    redirectFromDeweyQueryStringParam,
    deweyId,
    expectedApiCalls,
} from './LearningPath.constants';
import { LearningPathContent } from './LearningPathContent';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { SectionType } from './LearningPath.types';
import { Capability } from '../Shared/TelemetryConstants';
import { trackApiCalls } from '../Shared/utils';

export function LearningPathView(
    props: RouteComponentProps
): React.ReactElement {
    useDynamicReducer(
        'LearningPathReducer',
        learningPathReducer as Reducer,
        [learningPathSagas],
        false
    );
    const { dispatch } = React.useContext(ReduxContext);
    const {
        learningPath,
        selectedGroupId,
        errorInfo,
        queryStringParameters,
        isLoading,
        treeExpanded,
        apiCount,
    } = useLearningPathState();
    const isLive = new URLSearchParams(props.location.search).get('isLive');
    const isPublished = isLive?.toLocaleLowerCase() !== 'false';
    const impersonateAlias =
        new URLSearchParams(props.location.search).get('alias') ?? '';
    const action = new URLSearchParams(props.location.search).get('action');
    const isDefaultAction = action?.toLocaleLowerCase() !== 'preview';
    const isRedirectFromDewey = new URLSearchParams(props.location.search).get(
        redirectFromDeweyQueryStringParam
    );
    const { telemetryClient } = React.useContext(ComponentContext);
    const { learningPathId } = useParams<{ learningPathId: string }>();
    const { groupId: groupIDFromURL } = useParams<{ groupId: string }>();
    const { history } = props;
    const selectedGroup = findLearningPathGroup(
        learningPath.parentGroup,
        selectedGroupId
    );
    const completionMetadata = getCompletionMetadata(selectedGroup);
    const breadcrumbItems: IBreadcrumbItem[] = getBreadcrumbItems(
        selectedGroup,
        learningPathId,
        queryStringParameters,
        history
    );
    const [startApiCalls, setStartApiCalls] = React.useState(0);

    React.useEffect(() => {
        if (learningPath.id !== learningPathId) {
            dispatch(
                requestLearningPath(
                    learningPathId,
                    groupIDFromURL,
                    isPublished,
                    isDefaultAction,
                    impersonateAlias
                )
            );
            setStartApiCalls(new Date().getTime());
        }
    }, [
        dispatch,
        learningPathId,
        isPublished,
        isDefaultAction,
        groupIDFromURL,
        learningPath.id,
        impersonateAlias,
    ]);

    React.useEffect(() => {
        if (isRedirectFromDewey) {
            const queryParams = new URLSearchParams(
                props.history.location.search
            );
            if (queryParams.has(redirectFromDeweyQueryStringParam)) {
                telemetryClient.trackTrace(
                    {
                        message: 'Redirected from dewey.',
                    },
                    {
                        deweyId: queryParams.get(deweyId),
                        vaughanId: learningPathId,
                    }
                );
                queryParams.delete(redirectFromDeweyQueryStringParam);
                queryParams.delete(deweyId);
                props.history.replace({ search: queryParams.toString() });
            }
        }
    }, [isRedirectFromDewey, learningPathId, props.history, telemetryClient]);

    React.useEffect(() => {
        if (groupIDFromURL && groupIDFromURL !== selectedGroupId) {
            dispatch(setSelectedGroupId(groupIDFromURL));
        } else if (!groupIDFromURL && learningPath.parentGroup) {
            dispatch(setSelectedGroupId(learningPath.parentGroup.id));
        }
    }, [
        dispatch,
        groupIDFromURL,
        learningPathId,
        learningPath.parentGroup.id,
        selectedGroupId,
        learningPath.parentGroup,
    ]);

    React.useEffect(() => {
        if (props.location.search) {
            dispatch(setQueryStringParameters(props.location.search));
        }
        dispatch(setQueryStringParameters(props.location.search));
    }, [dispatch, props.location.search]);

    React.useEffect(() => {
        if (apiCount === expectedApiCalls) {
            const endApiCalls = new Date().getTime();
            trackApiCalls(
                telemetryClient,
                Capability.LearningPathView,
                learningPath.id,
                endApiCalls - startApiCalls
            );
        }
    }, [
        telemetryClient,
        learningPathId,
        startApiCalls,
        apiCount,
        learningPath.id,
    ]);

    usePageTitle(
        errorInfo?.showErrorPage
            ? `Error - ${__APP_NAME__}`
            : `${learningPath.name} - ${__APP_NAME__}`
    );

    return errorInfo?.showErrorPage ? (
        <ErrorView
            title={getErrorTitle(errorInfo.serviceCall, errorInfo.status)}
            message={
                errorInfo.status === 401 || errorInfo.status === 403
                    ? accessDeniedMessage
                    : ''
            }
        />
    ) : isLoading ? (
        <CoherenceLoading primaryText={'Loading your learning path.'} />
    ) : (
        <Stack verticalFill aria-live={'polite'}>
            <Stack
                verticalFill
                verticalAlign="start"
                styles={
                    treeExpanded
                        ? learningPathViewStackStylesExpanded
                        : learningPathViewStackStylesCollapsed
                }
            >
                <Stack>
                    <GradientBackground depth={breadcrumbItems.length} />
                    <Stack
                        verticalFill
                        horizontalAlign="center"
                        styles={{
                            root: {
                                margin: Spacing.SIZE_24,
                                marginTop: 0,
                            },
                        }}
                        tokens={childrenGap[24]}
                    >
                        {learningPath.parentGroup?.id == selectedGroup?.id ? (
                            <LearningPathHeaderCard />
                        ) : (
                            <GroupHeaderCard />
                        )}
                        {selectedGroup?.children.length > 0 && (
                            <>
                                {hasBothRequiredAndOptional(
                                    completionMetadata
                                ) ? (
                                    <>
                                        <LearningPathContent
                                            childElements={selectedGroup.children.filter(
                                                (item) =>
                                                    item.groupData.mandatory
                                            )}
                                            sectionType={SectionType.Required}
                                        />
                                        <LearningPathContent
                                            childElements={selectedGroup.children.filter(
                                                (item) =>
                                                    !item.groupData.mandatory
                                            )}
                                            sectionType={SectionType.Optional}
                                        />
                                    </>
                                ) : hasIgnoredChildren(selectedGroup) ? (
                                    <>
                                        <LearningPathContent
                                            childElements={selectedGroup.children.filter(
                                                (item) => !item.ignoreCompletion
                                            )}
                                        />
                                        <LearningPathContent
                                            childElements={selectedGroup.children.filter(
                                                (item) => item.ignoreCompletion
                                            )}
                                            sectionType={SectionType.Ignored}
                                        />
                                    </>
                                ) : (
                                    <LearningPathContent
                                        childElements={selectedGroup.children}
                                        isSequenceEnforced={
                                            selectedGroup.groupData
                                                .sequenceEnforcement
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
