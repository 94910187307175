import { MessageBarType, Stack } from '@fluentui/react';
import * as React from 'react';
import { CustomMessageBar } from '../../Shared/Components/CustomMessageBar/CustomMessageBar';
import { fontClassNames, Spacing } from '../../Shared/constants';
import { ILearningPath } from '../Models/LearningPath';
import { AdminInfoLabeledValue } from './AdminInfoLabeledValue';

export const AdminInfoView = (props: {
    learningPath: Pick<ILearningPath, 'assetOwner' | 'id' | 'learningOrg'>;
    alias: string;
}): React.ReactElement => {
    return (
        <CustomMessageBar
            message={
                <Stack
                    styles={{
                        root: {
                            marginBottom: Spacing.SIZE_4,
                        },
                    }}
                    horizontal
                    wrap
                >
                    You are viewing this learning path as an admin,&nbsp;
                    <span>
                        <strong>{`impersonating user '${props.alias}'`}</strong>
                        .&nbsp;
                    </span>
                    Expand this message bar to see more info.
                </Stack>
            }
            messageBarType={MessageBarType.info}
            truncated={true}
            isNonDismissible={true}
            extraContent={
                <Stack
                    styles={{
                        root: {
                            margin: `${Spacing.SIZE_12}px 0`,
                            paddingBottom: Spacing.SIZE_12,
                        },
                    }}
                >
                    <Stack.Item className={fontClassNames.caption}>
                        Learning path details:
                    </Stack.Item>
                    <ul style={{ padding: 0 }}>
                        <li>
                            <AdminInfoLabeledValue
                                label={'Asset owner'}
                                value={props.learningPath.assetOwner}
                            />
                        </li>
                        <li>
                            <AdminInfoLabeledValue
                                label={'Learning org'}
                                value={props.learningPath.learningOrg}
                            />
                        </li>
                        <li>
                            <AdminInfoLabeledValue
                                label={'ID'}
                                value={props.learningPath.id}
                            />
                        </li>
                    </ul>
                </Stack>
            }
        />
    );
};
