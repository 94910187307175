import {
    TelemetryClient,
    HttpClient,
    ComponentLoader,
    GraphClient,
} from '@employee-experience/common/lib/Services/';
import { MSALV2Client } from '@employee-experience/common/lib/Services/';
import { Shell } from '@employee-experience/common/lib/Shell';
import { StoreBuilder } from '@employee-experience/common/lib/Services/StoreBuilder';
import { withStore } from '@employee-experience/common/lib/withStore';
import { ReducerRegistry } from '@employee-experience/common/lib/Services/ReducerRegistry';
import { IHttpClientOption } from '@employee-experience/common/lib/Models';

const telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __ENVIRONMENT_NAME__,
        ServiceOffering: __SERVICE_OFFERING__,
        ServiceLine: __SERVICE_LINE__,
        Service: __SERVICE__,
        ComponentName: __COMPONENT_NAME__,
        ComponentId: __COMPONENT_ID__,
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});

telemetryClient.setAuthenticatedUserContext = (): void => {
    return;
};

const authClient = new MSALV2Client(
    {
        auth: {
            clientId: __CLIENT_ID__,
            redirectUri: window.location.origin,
            authority:
                'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
        },
    },
    telemetryClient
);
const httpClientOptions: IHttpClientOption = {
    correlationIdHeaderName: 'CorrelationId',
};
const httpClient =
    __IS_INTEGRATION_TESTING__ === 'true'
        ? new HttpClient(telemetryClient, undefined, httpClientOptions)
        : new HttpClient(telemetryClient, authClient, httpClientOptions);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient, httpClient);

const reducerRegistry = new ReducerRegistry();
const storeResult = new StoreBuilder(reducerRegistry, {})
    .configurePersistor({ blacklist: ['dynamic'] })
    .configureSaga({
        telemetryClient,
        authClient,
        httpClient,
        componentLoader,
        graphClient,
        appName: __APP_NAME__,
    })
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
