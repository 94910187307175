import * as React from 'react';
import { ICoherenceHeaderProps } from '@coherence-design-system/controls/lib/header';
import { initializeOBFeedback } from '@coherence-design-system/controls';
import { useLearningPathState } from './Pages/LearningPath/useLearningPathState';

export function useHeaderConfig(
    name: string,
    id: string
): ICoherenceHeaderProps {
    const [ocvButtonFocused, setOcvButtonFocused] = React.useState(false);
    const { learningPath } = useLearningPathState();

    initializeOBFeedback(
        __OCV_APP_ID__,
        __ENVIRONMENT_NAME__ === 'Production' ? 'Prod' : 'Int',
        '/officebrowserfeedback.min.js',
        '/officebrowserfeedback.min.css',
        '/intl/',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        // Include 'error: string' in the parameter if any custom behavior is needed
        () => {
            // set focus back to OCV button
            const ocvButton = document.getElementById(
                'coher-header-ocv-button-id'
            );
            if (ocvButton) {
                ocvButton.focus();
            }
            setOcvButtonFocused(false);
        },
        undefined,
        undefined,
        undefined,
        undefined,
        // Include 'submitted: boolean' in the parameter if any custom behavior is needed
        () => {
            // set focus back to OCV button
            const ocvButton = document.getElementById(
                'coher-header-ocv-button-id'
            );
            if (ocvButton) {
                ocvButton.focus();
            }
            setOcvButtonFocused(false);
        },
        false,
        true,
        undefined,
        undefined,
        undefined,
        undefined
    );

    return {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: name ? `${__APP_NAME__} | ${name}` : __APP_NAME__,
            linkUrl: learningPath.id ? `/${id}` : undefined,
        },
        farRightSettings: {
            helpSettings: {
                panelSettings: {
                    titleText: 'Help',
                    body: (
                        <a
                            style={{ textDecoration: 'underline' }}
                            href="https://aka.ms/AskLearning"
                        >
                            Ask Learning
                        </a>
                    ),
                    closeButtonAriaLabel: 'Close Help',
                },
            },
            feedbackSettings: {
                buttonSettings: {
                    title: 'Feedback',
                    ariaLabel: 'Feedback',
                },
                panelSettings: {
                    ocvButtonIsFocused: ocvButtonFocused,
                    onClick: () => {
                        setOcvButtonFocused(!ocvButtonFocused);
                        return true;
                    },
                },
            },
        },
    };
}
