import * as React from 'react';
import { Stack } from '@fluentui/react';
import { childrenGap } from '../../constants';
import {
    completedStackStyles,
    styleForCoursesCompleted,
} from './CompletionStatus.styles';

export function CompletionStatus(props: {
    totalCompleted: number;
    requiredCount: number;
    message: string;
    childrenType: string;
}): React.ReactElement {
    return (
        <Stack tokens={childrenGap[4]} styles={completedStackStyles}>
            <p>{props.message}</p>
            <Stack.Item {...styleForCoursesCompleted}>
                <span>
                    {props.totalCompleted} of {props.requiredCount}
                </span>{' '}
                {`${props.childrenType} completed`}
            </Stack.Item>
        </Stack>
    );
}
