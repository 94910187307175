import { IStackItemProps, IStackStyles } from '@fluentui/react';

export const styleForCoursesCompleted: IStackItemProps = {
    styles: {
        root: {},
    },
};

export const completedStackStyles: IStackStyles = {
    root: { p: { fontWeight: 600, fontSize: '18px' } },
};
