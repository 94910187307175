import { ICardStyles } from '@coherence-design-system/controls';
import { CoherenceTheme } from '@coherence-design-system/styles';
import {
    ILinkStyleProps,
    ILinkStyles,
    IStackProps,
    IStackStyles,
    IStackTokens,
    IStyle,
    IStyleFunctionOrObject,
} from '@fluentui/react';
import { CSSProperties } from 'styled-components';
import {
    childrenGap,
    ScreenWidthSelector,
    Spacing,
} from '../../Shared/constants';
import { StatusType } from '../LearningPath.types';

const expiredColor: string = CoherenceTheme.palette.redDark;

const expiringColor: string = CoherenceTheme.palette.red;

const expirationCardBorderRadius = '10px';

export const descriptionStyle: CSSProperties = {
    paddingTop: 16,
    paddingBottom: 16,
};

export const lengthLabelStyle: CSSProperties = {
    fontWeight: 600,
};

export const cardStackStyles: IStackTokens = {
    padding: 20,
    childrenGap: 16,
};

export const childrenStyles: IStackTokens = {
    childrenGap: 16,
    maxWidth: '80%',
};

export const rootCardstyles: IStackStyles = {
    root: {
        width: '75%',
        //marginBottom: `${Spacing.SIZE_24}px`,
    },
};

const regularCardStackStyle: IStackStyles = {
    root: {
        marginBottom: Spacing.SIZE_4,
    },
};

export const regularCardStackProps: IStackProps = {
    horizontal: true,
    styles: regularCardStackStyle,
    tokens: childrenGap[4],
};

export const learningPathCourseCardLabels = {
    sectionLength: 'Section length: ',
    courseDuration: 'Duration: ',
};

export const cardIconStyles: IStackStyles = {
    root: {
        fontSize: 20,
        marginTop: Spacing.SIZE_4,
        marginRight: Spacing.SIZE_8,
    },
};

export const titleDescriptionStackStyles: IStackStyles = {
    root: {
        marginRight: Spacing.SIZE_12,
        [ScreenWidthSelector.XxxLarge]: {
            width: '400px',
        },
        [ScreenWidthSelector.XxLarge]: {
            width: '400px',
        },
        [ScreenWidthSelector.XLarge]: {},
        [ScreenWidthSelector.Large]: {
            maxWidth: '800px',
        },
        [ScreenWidthSelector.Medium]: {
            maxWidth: '400px',
        },
        [ScreenWidthSelector.Small]: {
            maxWidth: '800px',
        },
    },
};

export const buttonStyles: IStyleFunctionOrObject<
    ILinkStyleProps,
    ILinkStyles
> = {
    root: {
        backgroundColor: CoherenceTheme.palette.blue,
        color: CoherenceTheme.palette.white,
        borderRadius: '2px',
        minWidth: '110px',
        textAlign: 'center',
        maxWidth: 'fit-content',
        height: '32px',
        fontWeight: 600,
        verticalAlign: 'center',
        textDecoration: 'none',
        padding: '6px 16px',
        verticalAlight: 'center',
        whiteSpace: 'nowrap',
        [':hover:active']: {
            backgroundColor:
                CoherenceTheme.semanticColors.primaryButtonBackgroundHovered,
            textDecoration: 'none',
            color: CoherenceTheme.palette.white,
        },
        [':hover']: {
            backgroundColor:
                CoherenceTheme.semanticColors.primaryButtonBackgroundHovered,
            textDecoration: 'none',
            color: CoherenceTheme.palette.white,
        },
        [':target']: {
            textDecoration: 'none',
            color: CoherenceTheme.palette.white,
        },
        [':focus']: {
            textDecoration: 'none',
            color: CoherenceTheme.palette.white,
        },
        [':visited']: {
            textDecoration: 'none',
            color: CoherenceTheme.palette.white,
        },
    },
};

export const completedButtonStyles: IStyleFunctionOrObject<
    ILinkStyleProps,
    ILinkStyles
> = {
    root: {
        ...(buttonStyles.root as ILinkStyles),
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        color: CoherenceTheme.palette.neutralTertiary,
        [':hover']: {},
        [':hover:active']: {},
    },
};

export const courseItemsStack: IStackStyles = {
    root: {
        justifyContent: 'space-between',
        alignItems: 'center',
        strong: {
            fontWeight: 600,
        },
        [ScreenWidthSelector.XxxLarge]: {
            minHeight: '160px',
            flexDirection: 'row',
        },
        [ScreenWidthSelector.XxLarge]: {
            minHeight: '160px',
            flexDirection: 'row',
        },
        [ScreenWidthSelector.XLarge]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
        [ScreenWidthSelector.Large]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
        [ScreenWidthSelector.Medium]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
        [ScreenWidthSelector.Small]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
    },
};

const expirationCardStyles: Record<StatusType, IStyle> = {
    [StatusType.Expired]: {
        border: '1px solid',
        borderColor: expiredColor,
        borderRadius: expirationCardBorderRadius,
    },
    [StatusType.ExpiringSoon]: {
        border: '1px solid',
        borderColor: expiringColor,
        borderRadius: expirationCardBorderRadius,
    },
    [StatusType.Completed]: { borderRadius: expirationCardBorderRadius },
    [StatusType.NotStarted]: { borderRadius: expirationCardBorderRadius },
    [StatusType.InProgress]: { borderRadius: expirationCardBorderRadius },
    [StatusType.Unknown]: { borderRadius: expirationCardBorderRadius },
};

export function getCoherenceCardStyles(status: StatusType): ICardStyles {
    return {
        layout: {
            padding: '0px',
        },
        root: expirationCardStyles[status],
        rightGutterStyle: '',
        header: '',
        footer: '',
    };
}

export const expiredMessageText: CSSProperties = {
    color: expiredColor,
};

export const expiringMessageText: CSSProperties = {
    color: expiringColor,
};

export const childCardstyles: IStackStyles = {
    root: {
        width: '100%',
    },
};

export const courseButtonText: Record<StatusType, string> = {
    [StatusType.InProgress]: 'Continue',
    [StatusType.NotStarted]: 'Start',
    [StatusType.Completed]: 'Open',
    [StatusType.Unknown]: 'Start',
    [StatusType.Expired]: 'Start',
    [StatusType.ExpiringSoon]: 'Open',
};
