import { Stack } from '@fluentui/react';
import * as React from 'react';
import { childrenGap, fontClassNames } from '../Shared/constants';

export const ErrorView = (props: {
    title: string;
    message: string;
}): React.ReactElement => {
    const greyBackgroundDiv = document.getElementById(
        'greyBackgroundComponent'
    );

    React.useEffect(() => {
        if (greyBackgroundDiv !== null) {
            greyBackgroundDiv.style.display = 'none';
        }
    }, [greyBackgroundDiv]);

    return (
        <Stack
            grow
            verticalFill
            verticalAlign={'center'}
            horizontalAlign={'center'}
            tokens={childrenGap[24]}
        >
            <img src={`${__BASE_URL__}/assets/block.png`} alt="" />
            <Stack tokens={childrenGap[16]}>
                <h1 className={fontClassNames.header}>{props.title}</h1>
                {props.message && (
                    <Stack.Item className={fontClassNames.body}>
                        {props.message}
                    </Stack.Item>
                )}
            </Stack>
        </Stack>
    );
};
