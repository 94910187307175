import { ITelemetryClient } from '@employee-experience/common/lib/Models';
import { DescriptionType } from '../LearningPath/LearningPath.constants';
import {
    ElementTypeString,
    LearningPathType,
    StatusType,
} from '../LearningPath/LearningPath.types';
import { ILearningPathGroup } from '../LearningPath/Models/LearningPath';
import { Capability } from './TelemetryConstants';

/** `childCount` must be >= 0 */
export function calculateChildString(
    childCount: number,
    childType: LearningPathType,
    telemetryClient?: Pick<ITelemetryClient, 'trackException'>
): string {
    if (childCount === 0) {
        return '';
    } else if (childCount < 0) {
        telemetryClient?.trackException({
            exception: new Error(
                'calculateChildString called with less than 0 childCount'
            ),
            properties: {
                childCount,
                childType,
            },
        });
        return '';
    }
    const typeString =
        childType === LearningPathType.LearningPathGroup
            ? ElementTypeString.Section
            : ElementTypeString.Course;
    return `${childCount} ${typeString}${childCount === 1 ? '' : 's'}`;
}

export function calculateLengthString(
    group: ILearningPathGroup,
    telemetryClient?: Pick<ITelemetryClient, 'trackException'>
): string {
    const childCourses = calculateChildElementCounts(
        group,
        LearningPathType.LearningPathItem
    );
    const childSections = calculateChildElementCounts(
        group,
        LearningPathType.LearningPathGroup
    );

    const coursesString = calculateChildString(
        childCourses,
        LearningPathType.LearningPathItem,
        telemetryClient
    );
    const sectionsString = calculateChildString(
        childSections,
        LearningPathType.LearningPathGroup,
        telemetryClient
    );

    return childCourses > 0 && childSections > 0
        ? `${sectionsString} & ${coursesString}`
        : `${sectionsString}${coursesString}`;
}

export function calculateChildElementCounts(
    group: ILearningPathGroup,
    desiredChildType: LearningPathType
): number {
    if (!group) return 0;
    let total = 0;
    group.children.forEach((child) => {
        if (child.type === desiredChildType) {
            total++;
        }
    });
    return total;
}

export const getExpirationMessage = (
    type: DescriptionType,
    status: StatusType.ExpiringSoon | StatusType.Expired
): string => {
    return `This ${type} ${
        status === StatusType.ExpiringSoon ? 'expires soon' : 'has expired'
    }. Please retake it.`;
};

/**
 * Helper for contructing the object/call to AI
 * @param telemetryClient ITelemetryClient object
 * @param page Capability/page identifier for referencing/identifying
 * @param learningPathId Learning path id
 * @param duration Time taken for api calls
 */
export const trackApiCalls = (
    telemetryClient: ITelemetryClient,
    page: Capability,
    learningPathId: string,
    duration: number
): void => {
    telemetryClient.trackCustomEvent(
        { name: `${page}_page_load_metric` },
        {
            learningPathId,
            duration,
            page,
        }
    );
};
