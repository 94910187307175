import * as React from 'react';
import { Link, Stack, Text } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { childrenGap } from '../../constants';

export function PrivacyFooter(): React.ReactElement {
    return (
        <footer>
            {/** This stack was added as a wrapper to override the default footer styles.*/}
            <Stack
                styles={{
                    root: { '> footer': { width: '100%', marginLeft: '0px' } },
                }}
            >
                <Stack>
                    <Stack
                        horizontal
                        horizontalAlign={'end'}
                        styles={{
                            root: {
                                background: CoherenceTheme.palette.white,
                                padding: 12,
                                color: CoherenceTheme.semanticColors.bodyText,
                            },
                        }}
                        tokens={childrenGap[24]}
                    >
                        <Link
                            href="https://go.microsoft.com/fwlink/?LinkId=518021"
                            target="_blank"
                        >
                            Microsoft Data Privacy Notice
                        </Link>
                        <Text>© 2022 Microsoft</Text>
                    </Stack>
                </Stack>
            </Stack>
        </footer>
    );
}
