import { CoherenceTreeItem } from '@coherence-design-system/controls';
import { ILearningPathElement } from '../Models/LearningPath';
import { iconType, treeItemDelimiter } from './LearningPathTree.constants';
import * as React from 'react';
import { LearningPathType, StatusType } from '../LearningPath.types';
import { checkEnableDisableForAll } from '../LearningPath.utils';
import { Icon, IIconProps, TooltipHost } from '@fluentui/react';
import { toolTipText } from '../LearningPath.constants';

/**
 * Creates a CoherenceTreeItem for the node passed, and binds the onTreeItemClicked function to the tree item.
 * Recursively calls itself on any children. Catalog items are given a unique id by concatenating their parent group
 * id after their catalog id. As long as UUID's are composed of hexadecimal values and dashes this won't break.
 *
 * @param items The nodes to be converted
 * @param selectedId The currently selected group
 * @param onTreeItemClicked The function to be called on item click
 * @param expanded The list of id's that are expanded
 */
export function makeTreeChildrenFromPath(
    items: ILearningPathElement[],
    selectedId: string,
    onTreeItemClicked: (_: React.MouseEvent, item: CoherenceTreeItem) => void,
    expanded: string[],
    isSequenceEnabled = false
): CoherenceTreeItem[] {
    const checkEnableAll = checkEnableDisableForAll(items, isSequenceEnabled);

    return items?.map((item, index) => {
        const checkEnabledDisabledForIndex = checkEnableAll[index];
        return {
            id:
                item.type === LearningPathType.LearningPathGroup
                    ? item.id
                    : `${item.id}${treeItemDelimiter}${item.parent.id}`,
            title: item.name,
            icon: fetchIcon(checkEnabledDisabledForIndex, item.status),
            isExpanded: expanded.includes(item.id),
            children: !checkEnabledDisabledForIndex
                ? []
                : makeTreeChildrenFromPath(
                      item.children,
                      selectedId,
                      onTreeItemClicked,
                      expanded,
                      item.groupData?.sequenceEnforcement ?? false
                  ),
            onClick: checkEnabledDisabledForIndex
                ? onTreeItemClicked
                : undefined,
            isSelected: item.id === selectedId,
        };
    });
}
/**
 * helper function to select the icon to display in tree view based on isEnabled value
 * isEnabled is derived based on SequenceEnforcement flag for each item of tree view
 * @param isEnabled
 * @param status
 * @returns
 */
export function fetchIcon(
    isEnabled: boolean,
    status: StatusType
): IIconProps | JSX.Element {
    return !isEnabled ? (
        <TooltipHost content={toolTipText}>
            <Icon iconName="Lock" aria-label="Unavailable" />
        </TooltipHost>
    ) : (
        iconType[status]
    );
}
/**
 *
 * Because of the absolute positioning of the component we get a 0 value accessing a ref's clientHeight and
 * we must therefore calculate the height with this monstrosity.
 *
 * NOTE: If styles are updated it may be necessary to update the item and iconAndHeader size constants used.
 *
 * @returns The height in pixels of the learning path tree component
 */
export function calculateTreeHeight(
    parentGroup: ILearningPathElement,
    expandedItems: string[]
): number {
    // Constants for determining pixel values for height
    const iconAndHeaderSize = 116;
    const itemSize = 34;
    let height = iconAndHeaderSize;

    const queue: Array<ILearningPathElement> = [];
    queue.push(parentGroup);
    while (queue.length > 0) {
        const currNode = queue.pop();
        // If expanded children contribute to height (parentGroup is always expanded)
        if (
            expandedItems.includes(currNode.id) ||
            currNode.id === parentGroup.id
        ) {
            currNode.children.forEach((e: ILearningPathElement) => {
                queue.push(e);
            });
        }

        height += itemSize;
    }

    return height;
}
