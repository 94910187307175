import { ICoherenceBreadcrumbStyles } from '@coherence-design-system/controls/lib/Breadcrumb/CoherenceBreadcrumb.types';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { LearningPathType, StatusType } from './LearningPath.types';
import { ILearningPathGroup } from './Models/LearningPath';
import { IStackItemStyles, IStackStyles } from '@fluentui/react';
import { ScreenWidthSelector, Spacing } from '../Shared/constants';

export const expectedApiCalls = 4;
export const learningPathDescriptionLabel = 'About this learning path';
export const learningPathLabel = {
    completeBy: 'Complete by: ',
    assignedBy: 'Assigned by: ',
};

/** User-facing strings for different elements in a learning path */
export enum DescriptionType {
    LearningPath = 'learning path',
    Group = 'section',
    Course = 'course',
}
export const learningPathHashHeader = 'x-learningpath-hash';

export const breadcrumbExpanded: ICoherenceBreadcrumbStyles = {
    root: {
        margin: '0px',
        paddingLeft: '85px',
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        width: '100%',
        verticalAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: `${Spacing.SIZE_48}px`,
        button: {
            margin: '0px',
        },
        [ScreenWidthSelector.XLarge]: {
            paddingLeft: '0px',
        },
        [ScreenWidthSelector.Large]: {
            paddingLeft: '0px',
        },
        [ScreenWidthSelector.Medium]: {
            paddingLeft: '0px',
        },
        [ScreenWidthSelector.Small]: {
            paddingLeft: '0px',
        },
    },
};
export const breadcrumbCollapsed: ICoherenceBreadcrumbStyles = {
    root: {
        margin: '0px',
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        width: '100%',
        verticalAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: `${Spacing.SIZE_48}px`,
        button: {
            margin: '0px',
        },
    },
};

export const lpViewBreadcrumbExpanded: ICoherenceBreadcrumbStyles = {
    root: {
        margin: '0px',
        paddingLeft: '85px',
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        width: '100%',
        verticalAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: `${Spacing.SIZE_48}px`,
        button: {
            margin: '0px',
        },
        [ScreenWidthSelector.XxxLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.XxLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.XLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.Large]: {
            display: 'none',
        },
        [ScreenWidthSelector.Medium]: {
            display: 'none',
        },
        [ScreenWidthSelector.Small]: {
            display: 'none',
        },
    },
};
export const lpViewBreadcrumbCollapsed: ICoherenceBreadcrumbStyles = {
    root: {
        display: 'none',
    },
};

export const lpViewBreadcrumbStackStyles: IStackStyles = {
    root: {
        minWidth: '100%',
        minHeight: `${Spacing.SIZE_48}px`,
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        [ScreenWidthSelector.XxxLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.XxLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.XLarge]: {
            display: 'flex',
        },
        [ScreenWidthSelector.Large]: {
            display: 'none',
        },
        [ScreenWidthSelector.Medium]: {
            display: 'none',
        },
        [ScreenWidthSelector.Small]: {
            display: 'none',
        },
    },
};

export const statusErrorMessage =
    'Something went wrong with completion statuses. Course completion is still being saved.';
export const statusErrorMessageAdminView =
    'Something went wrong with completion statuses.';

export const learningPathElementType: Record<
    LearningPathType,
    DescriptionType
> = {
    [LearningPathType.LearningPathGroup]: DescriptionType.Group,
    [LearningPathType.LearningPathItem]: DescriptionType.Course,
};

export const serviceToClientStatus: Record<string, StatusType> = {
    ['Completed']: StatusType.Completed,
    ['CompletedByAlternative']: StatusType.Completed,
    ['Attended']: StatusType.Completed,
    ['Waived']: StatusType.Completed,
    ['Passed']: StatusType.Completed,
    ['InProgress']: StatusType.InProgress,
    ['ExpiringSoon']: StatusType.ExpiringSoon,
    ['Expired']: StatusType.Expired,
    ['NotStarted']: StatusType.NotStarted,
    ['Interested']: StatusType.NotStarted,
    ['Cancelled']: StatusType.NotStarted,
    ['Registered']: StatusType.NotStarted,
    ['Waitlisted']: StatusType.NotStarted,
    ['Failed']: StatusType.NotStarted,
};

export const groupHeaderDescriptionLabel = 'About this section';

export enum ExpiryDate {
    None = 'none',
    Unknown = 'unknown',
}

export const emptyElement: ILearningPathGroup = {
    parent: null,
    type: LearningPathType.LearningPathGroup,
    id: '0',
    name: '',
    description: '',
    groupData: {
        requiredItemCount: 0,
        mandatory: true,
        sequenceEnforcement: false,
    },
    order: 0,
    level: 0,
    status: StatusType.NotStarted,
    children: [],
    expiryDate: ExpiryDate.Unknown,
    ignoreCompletion: false,
};

export const navMenuBorder = `1px solid ${CoherenceTheme.palette.neutralQuaternaryAlt}`;
const expandedNavWidth = 400;
const breadcrumbStartWhenCollapsed = 56;

export const navButtonStackStylesExpanded: IStackItemStyles = {
    root: {
        minWidth: `${expandedNavWidth}px`,
        backgroundColor: CoherenceTheme.palette.white,
        borderRight: navMenuBorder,
        zIndex: 2,
    },
};

export const navButtonStackStylesCollapsed: IStackItemStyles = {
    root: {
        zIndex: 2,
    },
};

export const navigationBreadcrumbStackStyles: IStackStyles = {
    root: {
        width: '100%',
        backgroundColor: CoherenceTheme.palette.neutralLighter,
        height: `${Spacing.SIZE_48}px`,
        verticalAlign: 'center',
        button: {
            margin: `${Spacing.SIZE_8}px`,
            marginLeft: `${Spacing.SIZE_16}px`,
        },
    },
};

export function getNavLearningPathStackStyles(
    treeCollapsed: boolean
): IStackStyles {
    if (treeCollapsed) {
        return {
            root: {
                position: 'relative',
                [ScreenWidthSelector.XxxLarge]: {
                    display: 'flex',
                    flexDirection: 'row',
                },
                [ScreenWidthSelector.XxLarge]: {
                    display: 'flex',
                    flexDirection: 'row',
                },
                [ScreenWidthSelector.XLarge]: {
                    display: 'flex',
                    flexDirection: 'row',
                },
                [ScreenWidthSelector.Large]: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                [ScreenWidthSelector.Medium]: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                [ScreenWidthSelector.Small]: {
                    display: 'flex',
                    flexDirection: 'column',
                },
            },
        };
    }
    return {
        root: {
            position: 'relative',
        },
    };
}

export function breadcrumbStackStyles(treeExpanded: boolean): IStackStyles {
    if (treeExpanded) {
        return {
            root: {
                width: '100%',
                backgroundColor: CoherenceTheme.palette.neutralLighter,
                height: `${Spacing.SIZE_48}px`,
                [ScreenWidthSelector.XxxLarge]: {
                    position: 'absolute',
                    left: expandedNavWidth,
                },
                [ScreenWidthSelector.XxLarge]: {
                    position: 'absolute',
                    left: expandedNavWidth,
                },
                [ScreenWidthSelector.XLarge]: {
                    position: 'absolute',
                    left: breadcrumbStartWhenCollapsed,
                },
                [ScreenWidthSelector.Large]: {
                    position: 'absolute',
                    left: breadcrumbStartWhenCollapsed,
                },
                [ScreenWidthSelector.Medium]: {
                    position: 'absolute',
                    left: breadcrumbStartWhenCollapsed,
                },
                [ScreenWidthSelector.Small]: {
                    position: 'absolute',
                    left: breadcrumbStartWhenCollapsed,
                },
            },
        };
    }
    return {
        root: {
            width: '100%',
            [ScreenWidthSelector.XxxLarge]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
            [ScreenWidthSelector.XxLarge]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
            [ScreenWidthSelector.XLarge]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
            [ScreenWidthSelector.Large]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
            [ScreenWidthSelector.Medium]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
            [ScreenWidthSelector.Small]: {
                position: 'absolute',
                left: breadcrumbStartWhenCollapsed,
            },
        },
    };
}

export const learningPathViewStackStylesExpanded: IStackStyles = {
    root: {
        width: '100%',
        [ScreenWidthSelector.XxxLarge]: {
            marginTop: `${Spacing.SIZE_48}px`,
        },
        [ScreenWidthSelector.XxLarge]: {
            marginTop: `${Spacing.SIZE_48}px`,
        },
        [ScreenWidthSelector.XLarge]: {
            marginTop: `${Spacing.SIZE_48}px`,
        },
    },
};

export const learningPathViewStackStylesCollapsed: IStackStyles = {
    root: {
        width: '100%',
        [ScreenWidthSelector.XxxLarge]: {
            marginTop: '0px',
        },
        [ScreenWidthSelector.XxLarge]: {
            marginTop: '0px',
        },
    },
};

export const redirectFromDeweyQueryStringParam = 'redirectFromDewey';
export const deweyId = 'deweyId';
export const toolTipText = 'Please complete this section in order.';
export const statusNoteText =
    'Note: completion status may take 24-48 hours to update';
export const learningPathNotTargeted = "Learning Path not targeted to user.";
export const genericErrorMessage = 'Something went wrong. Please try again later.';