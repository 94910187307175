import * as React from 'react';
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { Icon, NeutralColors, Stack } from '@fluentui/react';
import {
    completedIconStyles,
    progressIndicatorStackStyles,
} from './CustomProgressIndicator.styles';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { isGroupCompleted } from '../../../LearningPath/LearningPath.utils';

export function CustomProgressIndicator(props: {
    requiredCount: number;
    completedCount: number;
}): React.ReactElement {
    const isCompleted = isGroupCompleted(
        props.completedCount,
        props.requiredCount
    );
    // If no progress has been made, render 1/50th progress to show small bubble at top of progress indicator
    const maxValueForNotStarted = 50;
    return (
        <Stack.Item style={progressIndicatorStackStyles}>
            <CircularProgressbarWithChildren
                value={Math.max(props.completedCount, 1)}
                maxValue={
                    props.completedCount === 0
                        ? maxValueForNotStarted
                        : props.requiredCount
                }
                background={isCompleted}
                styles={buildStyles({
                    pathColor: CoherenceTheme.palette.blue,
                    trailColor: isCompleted
                        ? CoherenceTheme.palette.blue
                        : NeutralColors.gray60,
                    strokeLinecap: 'round',
                    backgroundColor: CoherenceTheme.palette.blue,
                })}
            >
                {isCompleted && (
                    <Icon iconName={'Accept'} styles={completedIconStyles} />
                )}
            </CircularProgressbarWithChildren>
        </Stack.Item>
    );
}
