import * as dayjs from 'dayjs';
import * as utcPlugin from 'dayjs/plugin/utc';
dayjs.extend(utcPlugin);

export const INVALID_DATE = 'Invalid date';

/** Date String formats for the formatDateString function
 * @link https://coherence-portal.azurewebsites.net/Styles/DataFormatting#Usage
 */
export enum DateStringFormat {
    /** @example Jun 05, 2021 */
    DateOnly = 'MMM DD, YYYY',
    /** @example 14:24 Jun 05, 2021 */
    DateWithTime = 'HH:mm MMM DD, YYYY',
}

/**
 * Note: For now this function outputs times in UTC - possibly will need to update.
 * TODO: Update this function when the updated Coherence guidance is released for dates and times.
 * A function to format dates into their Coherence compliant time display string representations.
 * @param dateString
 * @param format A DateStringFormat from the approved enum of DateStringFormats
 * @returns
 * A date (and/or time) that follows the Coherence guidelines for date formatting.
 * If the date cannot successfully be converted, returns the INVALID_DATE constant.
 */
export function formatDateString(
    dateString: string,
    format: DateStringFormat = DateStringFormat.DateOnly
): string {
    if (dateString === '0001-01-01T00:00:00') return '';
    const result: string = dayjs.utc(dateString).format(format);
    if (dayjs(result, format).isValid()) {
        return result;
    }
    return INVALID_DATE;
}
