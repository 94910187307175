import { IStackStyles } from '@fluentui/react';

export const GroupHeaderLengthLabel = 'Section length: ';

export const styleForGroupDescription: IStackStyles = {
    root: {
        maxWidth: '75%',
        display: 'inline',
        ul: { 'list-style': 'disc' },
    },
};
