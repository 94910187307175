import { MessageBarType, Stack } from '@fluentui/react';
import * as React from 'react';
import { CustomMessageBar } from '../Shared/Components/CustomMessageBar/CustomMessageBar';
import {
    checkEnableDisableForAll,
    getRenewalMessage,
    getSectionCompletionText,
    ignoreElementMessage,
} from './LearningPath.utils';
import { childrenGap, fontClassNames } from '../Shared/constants';
import { SectionType } from './LearningPath.types';
import { LearningPathElementCard } from './LearningPathElementCard/LearningPathElementCard';
import { rootCardstyles } from './LearningPathElementCard/LearningPathElementCard.constants';
import { ILearningPathElement } from './Models/LearningPath';

export function LearningPathContent(props: {
    childElements: ILearningPathElement[];
    sectionType?: SectionType;
    isSequenceEnforced?: boolean;
}): React.ReactElement {
    const renewalMessage = getRenewalMessage(props.childElements);
    const checkEnableAll = checkEnableDisableForAll(
        props.childElements,
        props.isSequenceEnforced
    );
    return (
        <>
            <Stack
                className={fontClassNames.subheader}
                style={{ marginTop: '40px' }}
                styles={rootCardstyles}
                tokens={childrenGap[8]}
            >
                {getSectionCompletionText(
                    props.childElements,
                    props.sectionType
                )}
                {props.sectionType === SectionType.Ignored && (
                    <Stack styles={{ root: { fontSize: 14, fontWeight: 400 } }}>
                        {ignoreElementMessage(
                            props.childElements.length,
                            props.childElements[0].type
                        )}
                    </Stack>
                )}
            </Stack>
            {renewalMessage?.length > 0 && (
                <Stack styles={rootCardstyles}>
                    <CustomMessageBar
                        message={renewalMessage}
                        messageBarType={MessageBarType.warning}
                    />
                </Stack>
            )}

            {props.childElements.map(
                (child: ILearningPathElement, index: number) => {
                    return (
                        <LearningPathElementCard
                            key={child.id}
                            element={child}
                            isEnabled={checkEnableAll[index]}
                        />
                    );
                }
            )}
        </>
    );
}
