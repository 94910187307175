import {
    DefaultEffects,
    IImageStyles,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    NeutralColors,
} from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { CSSProperties } from 'styled-components';
import {
    childrenGap,
    ScreenWidthSelector,
    Spacing,
    descriptionLineHeight,
    collapsedDescriptionHeight,
    CardType,
} from '../Shared/constants';

export const thumbnailImageStyles: Partial<IImageStyles> = {
    root: {
        border: `1px solid ${NeutralColors.gray60}`,
    },
};

export const learningPathHeaderCardProps: IStackProps = {
    styles: {
        root: {
            background: CoherenceTheme.palette.white,
            width: '80%',
            borderRadius: '10px 10px 10px 10px',
            boxShadow: DefaultEffects.elevation8,
            zIndex: 1,
            [ScreenWidthSelector.XxxLarge]: {
                margin: `48px ${Spacing.SIZE_256}px 0px ${Spacing.SIZE_256}px`,
            },
            [ScreenWidthSelector.XxLarge]: {
                margin: `48px ${Spacing.SIZE_192}px 0px ${Spacing.SIZE_192}px`,
            },
            [ScreenWidthSelector.XLarge]: {
                margin: `48px ${Spacing.SIZE_96}px 0px  ${Spacing.SIZE_96}px`,
            },
            [ScreenWidthSelector.Large]: {
                margin: `48px ${Spacing.SIZE_48}px 0px ${Spacing.SIZE_48}px`,
            },
            [ScreenWidthSelector.Medium]: {
                margin: '48px 0px',
                width: '100%',
            },
            [ScreenWidthSelector.Small]: {
                margin: '48px 0px',
                width: '100%',
            },
        },
    },
    verticalFill: true,
};

export const detailStackStyles: IStackStyles = {
    root: {
        marginTop: '32px',
        marginBottom: '32px',
        marginRight: '24px',
        '> div > strong': { fontWeight: 600 },
        [ScreenWidthSelector.Large]: {
            display: 'None',
        },
        [ScreenWidthSelector.Medium]: {
            display: 'None',
        },
        [ScreenWidthSelector.Small]: {
            display: 'None',
        },
    },
};

export const detailStackStylesOnHeaderCardDisplay: IStackStyles = {
    root: {
        marginBottom: '10px',
        '> div > strong': { fontWeight: 600 },
        [ScreenWidthSelector.XxxLarge]: {
            display: 'None',
        },
        [ScreenWidthSelector.XxLarge]: {
            display: 'None',
        },
        [ScreenWidthSelector.XLarge]: {
            display: 'None',
        },
    },
};

export const headerCardContentProps: IStackProps = {
    styles: {
        root: {
            zIndex: 1,
            padding: '40px',
            boxShadow: DefaultEffects.elevation8,
            borderRadius: '10px 10px 0 0',
        },
    },
    tokens: childrenGap[16],
};

export const groupHeaderCardContentProps: IStackProps = {
    styles: {
        root: {
            zIndex: 1,
            padding: '24px',
            boxShadow: DefaultEffects.elevation8,
            borderRadius: '10px 10px 0 0',
        },
    },
    tokens: childrenGap[16],
};

export const styleForLearningPathElementDesc: IStackStyles = {
    root: {
        marginRight: Spacing.SIZE_24,
        lineHeight: '20px',
        maxHeight: '60px',
        overflow: 'hidden',
    },
};

export const learningPathViewProps: IStackProps = {
    styles: {
        root: {
            position: 'relative',
            top: 96,
            left: 0,
            width: '100%',
        },
    },
};

export const lpLabelStyle: IStackItemStyles = {
    root: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
};

export const descriptionStyles: CSSProperties = {
    lineHeight: `${descriptionLineHeight}px`,
};

export function truncatedElementCardDescriptionStyles(
    cardType: CardType
): CSSProperties {
    return {
        lineHeight: `${descriptionLineHeight}px`,
        maxHeight: collapsedDescriptionHeight[cardType],
        overflow: 'hidden',
    };
}

export function truncatedChildCardTitleStyles(maxLines: number): CSSProperties {
    return {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        overflow: 'hidden',
    };
}
export const groupHeaderTitleStyles: IStackStyles = {
    root: {
        fontSize: 26,
        fontWeight: 600,
    },
};

export const completionStatusStackStyles: IStackStyles = {
    root: {
        marginTop: '0px',
        boxShadow: DefaultEffects.elevation8,
        borderRadius: '0 0 10px 10px',
        backgroundColor: CoherenceTheme.palette.neutralLighterAlt,
        justifyContent: 'space-between',
    },
};

export const statusNoteStyles: IStackStyles = { root: { fontWeight: 400 } };
