import { StatusType } from '../../../LearningPath/LearningPath.types';

export const statusText: Record<StatusType, string> = {
    [StatusType.InProgress]: 'In progress',
    [StatusType.NotStarted]: 'Not started',
    [StatusType.Completed]: 'Completed',
    [StatusType.Unknown]: 'Unknown',
    [StatusType.Expired]: 'Expired on',
    [StatusType.ExpiringSoon]: 'Expires on',
};
