import {
    CoherenceTreeItem,
    ICoherenceTreeViewStyleProps,
    ICoherenceTreeViewStyles,
} from '@coherence-design-system/controls/lib/CoherenceTreeView/CoherenceTreeView.types';
import CoherenceTheme from '@coherence-design-system/styles/lib/coherence/CoherenceTheme';
import {
    IIconProps,
    IStyleFunctionOrObject,
    mergeStyleSets,
} from '@fluentui/react';
import {
    fontClassNames,
    ScreenWidthSelector,
    Spacing,
} from '../../Shared/constants';
import { navMenuBorder } from '../LearningPath.constants';
import { StatusIconProps, StatusType } from '../LearningPath.types';

const treeWidth = '400px';

export const treeStackStyles = mergeStyleSets({
    treeExpanded: {
        backgroundColor: 'white',
        maxWidth: treeWidth,
        minWidth: treeWidth,
        borderRight: navMenuBorder,
        // If width is set to 100% there will be an unnecessary scrollbar for pages that
        // don't require it as the height of the tree navigation will extend slightly
        // beyond the neccessary length to display the page
        height: '99%',
        [ScreenWidthSelector.XxxLarge]: {
            position: 'absolute',
            left: 0,
        },
        [ScreenWidthSelector.XxLarge]: {
            position: 'absolute',
            left: 0,
        },
        [ScreenWidthSelector.XLarge]: {
            position: 'absolute',
            left: 0,
        },
        [ScreenWidthSelector.Large]: {
            position: 'absolute',
            left: 0,
        },
        [ScreenWidthSelector.Medium]: {
            position: 'absolute',
            left: 0,
        },
        [ScreenWidthSelector.Small]: {
            position: 'absolute',
            left: 0,
        },
    },
    treeNotExpanded: {
        display: 'none',
    },
    treeNav: {
        paddingLeft: `${Spacing.SIZE_12}px`,
        paddingTop: `${Spacing.SIZE_8}px`,
    },
    treeNavWithBreadcrumbs: {
        paddingLeft: `${Spacing.SIZE_12}px`,
        paddingTop: `${Spacing.SIZE_8}px`,
        backgroundColor: CoherenceTheme.palette.white,
    },
    treeNavExpanded: {
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            backgroundColor: CoherenceTheme.palette.neutralLighter,
        },
        button: {
            marginLeft: '16px',
        },
    },
    treeComponent: {
        paddingLeft: '18px',
    },
    titleButton: {
        marginBottom: '1px',
        ':hover': {
            'background-color': CoherenceTheme.palette.neutralLighter,
        },
        'button:focus:not(:focus-visible)': {
            outline: 0,
        },
        'button:focus': {
            outline: '1px solid gray',
        },
        button: {
            background: 'none',
            border: 0,
            textAlign: 'left',
            paddingTop: '5px',
            paddingBottom: '5px',
            width: '80%',
        },
    },
    hamburgerButton: {
        paddingBottom: '4px',
    },
});

export const treeIconProps: IIconProps = {
    iconName: 'GlobalNavButton',
    styles: {
        root: {
            color: CoherenceTheme.semanticColors.bodyText,
            height: '16px',
            button: {
                margin: '8px',
            },
        },
    },
};

export const coherenceTreeStyles: IStyleFunctionOrObject<
    ICoherenceTreeViewStyleProps,
    ICoherenceTreeViewStyles
> = {
    itemChevron: {
        color: CoherenceTheme.palette.themePrimary,
        ':hover': {
            'background-color': CoherenceTheme.palette.neutralLighterAlt,
        },
    },
    itemTitle: fontClassNames.body,
};

function inProgressIcon(): StatusIconProps {
    return {
        iconName: 'StatusCircleOuter',
        'aria-label': 'In progress',
        style: {
            color: CoherenceTheme.palette.yellow,
            transform: 'rotate(-45deg)',
        },
    };
}
function notStartedIcon(): StatusIconProps {
    return {
        iconName: 'LocationCircle',
        'aria-label': 'Not started',
        style: {
            color: CoherenceTheme.palette.neutralTertiaryAlt,
        },
    };
}
function completedIcon(): StatusIconProps {
    return {
        iconName: 'SkypeCircleCheck',
        'aria-label': 'Completed',
        style: {
            color: CoherenceTheme.semanticColors.successIcon,
        },
    };
}

function expiringIcon(): StatusIconProps {
    return {
        iconName: 'WarningSolid',
        'aria-label': 'Expiring',
        style: {
            color: CoherenceTheme.palette.orange,
        },
    };
}
function expiredIcon(): StatusIconProps {
    return {
        iconName: 'StatusErrorFull',
        'aria-label': 'Expired',
        style: {
            color: CoherenceTheme.palette.redDark,
        },
    };
}

export const iconType: Record<string, StatusIconProps> = {
    [StatusType.InProgress]: inProgressIcon(),
    [StatusType.NotStarted]: notStartedIcon(),
    [StatusType.Completed]: completedIcon(),
    [StatusType.Unknown]: notStartedIcon(),
    [StatusType.ExpiringSoon]: expiringIcon(),
    [StatusType.Expired]: expiredIcon(),
};

/** Unique value (including emoji) to ensure it's not a substring of item IDs */
export const treeItemDelimiter = '#😂#';

export const mockData: CoherenceTreeItem[] = [
    {
        id: 'An id',
        title: 'This is a title.',
        icon: inProgressIcon(),
        isExpanded: true,
        children: [
            {
                id: 'Child id',
                title: 'Child title.',
                icon: notStartedIcon(),
                isExpanded: true,
                children: [
                    {
                        id: 'id Grandchild',
                        title: 'Grandchild title',
                        icon: completedIcon(),
                        isExpanded: true,
                        children: [
                            {
                                id: 'great grandchild id',
                                title: 'Great grandchild title',
                                icon: completedIcon(),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
