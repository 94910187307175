import { Card } from '@coherence-design-system/controls/lib/Card';
import { Icon, Link, Stack } from '@fluentui/react';
import * as React from 'react';
import { CollapsibleDescription } from '../../Shared/Components/CollapsibleDescription/CollapsibleDescription';
import { LabeledValue } from '../../Shared/Components/LabeledValue/LabeledValue';
import { StatusBar } from '../../Shared/Components/StatusBar/StatusBar';
import { TruncatedTitleTextOverFlow } from '../../Shared/Components/TruncatedTitleTextOverFlow/TruncatedTitleTextOverFlow';
import {
    CardType,
    fontClassNames,
    childrenGap,
    childCardTitleLineHeight,
    Spacing,
} from '../../Shared/constants';
import { getExpirationMessage } from '../../Shared/utils';
import { DescriptionType } from '../LearningPath.constants';
import { descriptionStyles } from '../LearningPath.styles';
import { StatusType } from '../LearningPath.types';
import { checkIsIgnoredOrIsEnabled } from '../LearningPath.utils';
import { ILearningPathCatalogItem } from '../Models/LearningPath';
import {
    cardStackStyles,
    cardIconStyles,
    learningPathCourseCardLabels,
    buttonStyles,
    courseItemsStack,
    titleDescriptionStackStyles,
    getCoherenceCardStyles,
    expiredMessageText,
    expiringMessageText,
    childCardstyles,
    courseButtonText,
    completedButtonStyles,
    regularCardStackProps,
} from './LearningPathElementCard.constants';
import { getCourseLength } from './LearningPathElementCard.utils';

export function LearningPathCourseCard(props: {
    item: ILearningPathCatalogItem;
    isEnabled: boolean;
}): React.ReactElement {
    const { item, isEnabled } = props;
    return (
        <Stack styles={childCardstyles} tokens={childrenGap[8]}>
            <Card styles={getCoherenceCardStyles(item.status)}>
                <Stack
                    tokens={cardStackStyles}
                    verticalAlign="center"
                    styles={{ ...courseItemsStack }}
                >
                    <Stack
                        verticalAlign="center"
                        styles={{
                            ...titleDescriptionStackStyles,
                        }}
                    >
                        <Stack {...regularCardStackProps}>
                            <Icon
                                iconName={'PublishCourse'}
                                styles={cardIconStyles}
                            />
                            <span
                                className={fontClassNames.subheader}
                                id={item.id}
                            >
                                <TruncatedTitleTextOverFlow
                                    name={item.name}
                                    maxLines={2}
                                    lineHeight={childCardTitleLineHeight}
                                />
                            </span>
                        </Stack>
                        {item.description?.length > 0 && (
                            <Stack
                                styles={{
                                    root: {
                                        marginLeft: Spacing.SIZE_32,
                                    },
                                }}
                            >
                                <CollapsibleDescription
                                    title={item.name}
                                    description={item.description}
                                    defaultStyle={descriptionStyles}
                                    cardType={CardType.Child}
                                />
                            </Stack>
                        )}
                    </Stack>
                    {getCourseLength(item.length) && (
                        <LabeledValue
                            label={learningPathCourseCardLabels.courseDuration}
                            value={getCourseLength(item.length)}
                        />
                    )}
                    <StatusBar
                        status={item.status}
                        expiryDate={item.expiryDate}
                        completionDate={item.completionDate}
                        renderDate={item.ignoreCompletion}
                    />
                    <Link
                        href={item.url}
                        target="_blank"
                        role="link"
                        aria-label={`Open course ${item.name} (opens in new tab)`}
                        styles={
                            checkIsIgnoredOrIsEnabled(
                                item.ignoreCompletion,
                                isEnabled
                            )
                                ? completedButtonStyles
                                : buttonStyles
                        }
                        disabled={checkIsIgnoredOrIsEnabled(
                            item.ignoreCompletion,
                            isEnabled
                        )}
                    >
                        {courseButtonText[item.status]}
                        <Icon
                            iconName={'NavigateExternalInline'}
                            style={{ marginLeft: '4px' }}
                        ></Icon>
                    </Link>
                </Stack>
            </Card>
            {(item.status === StatusType.ExpiringSoon ||
                item.status === StatusType.Expired) && (
                <span
                    style={
                        item.status === StatusType.ExpiringSoon
                            ? expiringMessageText
                            : expiredMessageText
                    }
                >
                    {getExpirationMessage(DescriptionType.Course, item.status)}
                </span>
            )}
        </Stack>
    );
}
