import * as React from 'react';
import { Stack } from '@fluentui/react';
import { fontClassNames } from '../../Shared/constants';

export function AdminInfoLabeledValue(props: {
    label: string;
    value: string;
}): React.ReactElement {
    return (
        <Stack wrap horizontal className={fontClassNames.caption}>
            <span>{props.label}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{props.value}</span>
        </Stack>
    );
}
