import * as React from 'react';
import { Stack } from '@fluentui/react';
/**
 * Used to reduce boiler plate code for having multiple Stack.Items being duplicated.
 * @returns Stack.Item that displays the label with the value
 */
export function LabeledValue(props: {
    label: string;
    value: string;
}): React.ReactElement {
    return (
        <Stack.Item>
            <strong>{props.label}</strong>
            {props.value}
        </Stack.Item>
    );
}
