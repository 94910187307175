import { TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { truncatedChildCardTitleStyles } from '../../../LearningPath/LearningPath.styles';

export function TruncatedTitleTextOverFlow(props: {
    name: string;
    maxLines: number;
    lineHeight: number;
}): React.ReactElement {
    /** Description reference */
    const descRef = React.useRef(null);
    const [isOverFlow, setIsOverFlow] = React.useState(false);
    const truncatedTitleStyle = truncatedChildCardTitleStyles(props.maxLines);
    const maxTitleHeight = props.maxLines * props.lineHeight;

    React.useEffect(() => {
        setIsOverFlow(descRef.current.scrollHeight > maxTitleHeight);
    }, [maxTitleHeight]);
    return (
        <div>
            <div ref={descRef} style={truncatedTitleStyle}>
                {isOverFlow ? (
                    <TooltipHost content={props.name}>{props.name}</TooltipHost>
                ) : (
                    <div>{props.name}</div>
                )}
            </div>
        </div>
    );
}
