import { IStackStyles } from '@fluentui/react';

export const gradientStackStyles: IStackStyles = {
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        height: '224px',
        minWidth: '100%',
        marginTop: '48px',
    },
};

export const gradientType: Record<number, string> = {
    1: 'blueGradient.svg',
    2: 'purpleGradient.svg',
    3: 'greenGradient.svg',
    4: 'blueGradient.svg',
};
